import {
  createStyleSheet,
  Icon,
  Text,
  useStyleSheet,
  useThemeContext,
  View
} from '@gopeerproject/ui-kit'

export const Upload = () => {
  const styles = useStyleSheet(getStyles)
  const { size, color } = useThemeContext()
  return (
    <View style={styles.container}>
      <Icon name='upload-files' size={size.N} color={color.invert2.p100} />
      <Text type='heading' size='h3'>{`Drop files here`}</Text>
    </View>
  )
}

const getStyles = createStyleSheet(({ color }) => ({
  container: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 999,
    backgroundColor: color.invert1.p68,
    backdropFilter: 'blur(16px)'
  }
}))
