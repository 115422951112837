import { View as RNView, ViewStyle } from 'react-native'
import {
  Fragment,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react'
import {
  createStyleSheet,
  Divider,
  Focusable,
  Icon,
  Loader,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'

import { Feature, useClassroom, useClassroomPermissions } from '../../contexts'
import { readImage } from './imageUtils'
import { useBackgrounds, useUploadBackground } from '../../hooks'

export const Backgrounds: React.FC<{ isOnboarding?: boolean }> = ({
  isOnboarding = false
}) => {
  const styles = useStyleSheet(getStyles)
  const { data, refetch } = useBackgrounds()
  const [classroomState, classroomDispatch] = useClassroom()
  const uploadBackground = useUploadBackground()
  const containerRef = useRef<HTMLDivElement | null>(null)

  const onBackgroundClick = useCallback(
    (background: string) => () => {
      classroomDispatch({ type: 'set_background', payload: background })
    },
    [classroomDispatch]
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  const scrollToBackground = () => {
    if (!containerRef.current) return
    const { scrollLeft } = containerRef.current

    if (scrollLeft < 200) {
      containerRef.current.scrollTo({
        left: 200,
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  const handleUpload = useCallback(
    async (file: File) => {
      const resized = await readImage(file)

      scrollToBackground()
      const uploadedFile = await uploadBackground.mutateAsync(resized)

      classroomDispatch({
        type: 'set_background',
        payload: uploadedFile.data
      })
    },
    [classroomDispatch, uploadBackground]
  )

  const { checkPermission } = useClassroomPermissions()
  const canUploadBackground = checkPermission(
    Feature.VIDEO_UPLOAD_CUSTOM_BACKGROUNDS
  )

  const backgrounds = useMemo(
    () => [
      'default',
      'blur',
      ...(canUploadBackground ? ['upload-image'] : []),
      ...(uploadBackground.isLoading ? ['uploading'] : []),
      ...((isOnboarding ? data?.slice(0, 2) : data) ?? []).map(
        (background) => background.data
      )
    ],
    [uploadBackground.isLoading, data, isOnboarding, canUploadBackground]
  )

  const renderBackground = useCallback(
    (background: string, i: number) => {
      const isActive = background === classroomState.background
      return (
        <Fragment key={background}>
          <Focusable
            onPress={
              background !== 'upload-image'
                ? onBackgroundClick(background)
                : () => {}
            }
            style={[
              !['default', 'blur', 'upload-image'].includes(background)
                ? ({
                    backgroundImage: `url(${background})`
                  } as ViewStyle)
                : null,
              styles.background,
              isOnboarding && styles.onboardingBackground,
              isActive && styles.backgroundActive
            ]}
            hoverStyle={[
              styles.backgroundHover,
              isActive && styles.backgroundActive
            ]}
          >
            {background === 'upload-image' && (
              <input
                style={{
                  opacity: 0,
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  cursor: 'pointer'
                }}
                type='file'
                onChange={(event) => {
                  if (event.target.files?.length) {
                    const [file] = event.target.files
                    handleUpload(file)
                  }
                }}
              />
            )}

            {isActive && classroomState.isBackgroundLoading ? (
              <Loader />
            ) : (
              ['blur', 'upload-image'].includes(background) && (
                <Icon
                  size={34}
                  name={
                    background === 'blur'
                      ? 'video-background-effect'
                      : isOnboarding
                      ? 'image'
                      : 'upload-files'
                  }
                  filled={isActive}
                  color='inherit'
                />
              )
            )}

            {background === 'uploading' && <Loader />}
            {background === 'default' && (
              <Icon
                size={34}
                name='prohibited'
                filled={isActive}
                color='inherit'
              />
            )}
          </Focusable>
          {background === 'blur' &&
            isOnboarding &&
            i !== backgrounds.length - 1 && (
              <Divider key='divider' type='vertical' style={styles.divider} />
            )}
        </Fragment>
      )
    },
    [
      classroomState.background,
      onBackgroundClick,
      handleUpload,
      classroomState.isBackgroundLoading,
      styles,
      isOnboarding,
      backgrounds
    ]
  )

  return (
    <View
      ref={containerRef as RefObject<RNView>}
      style={[styles.container, isOnboarding && styles.onboardingContainer]}
    >
      {backgrounds.map(renderBackground)}
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: {
    display: 'grid' as 'flex',
    gap: size.C,
    gridTemplateColumns: '1fr 1fr 1fr 1fr'
  },
  onboardingContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  background: {
    height: 69,
    backgroundColor: color.invert2.p4,
    color: color.invert2.p56,
    borderColor: 'transparent',
    borderWidth: 3,
    borderRadius: size.G,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    ['background-clip']: 'border-box',
    ['background-origin']: 'border-box'
  },
  onboardingBackground: { height: 80, width: 80 },
  backgroundHover: {
    backgroundColor: color.primary.ghostHover,
    borderColor: color.primary.solidHover,
    color: color.primary.solidHover
  },
  backgroundActive: {
    backgroundColor: color.primary.ghost,
    borderColor: color.primary.solid,
    color: color.primary.solid
  },
  divider: {
    height: 24,
    width: 1,
    backgroundColor: color.invert2.p10,
    alignSelf: 'center'
  }
}))
