import React from 'react'
import { Participant } from 'twilio-video'
import { useClassroomQuery } from '../../../hooks'
import {
  createStyleSheet,
  Icon,
  Loader,
  Text,
  User,
  useStyleSheet,
  useThemeContext,
  View
} from '@gopeerproject/ui-kit'
import { NetworkQualityLevel } from './NetworkQualityLevel'
import { useDominantSpeaker } from '@gopeerproject/web-kit'

export const ParticipantCard: React.FC<{
  participant: Participant
  isLocalParticipant?: boolean
  children: React.ReactNode
  isVideoEnabled: boolean
  isAudioEnabled: boolean
  isLoading: boolean
  isHidden: boolean
  isScreenSharing: boolean
  isMinimized?: boolean
}> = ({
  participant,
  isLocalParticipant,
  children,
  isVideoEnabled,
  isAudioEnabled,
  isLoading,
  isHidden,
  isMinimized
}) => {
  const styles = useStyleSheet(getStyles)
  const theme = useThemeContext()
  const { data: classroom } = useClassroomQuery()

  const pid = participant?.identity
  const image = classroom?.participants[pid]?.image
  const name = classroom?.participants[pid]?.name
  const ptype = classroom?.participants[pid]?.type

  const dominantSpeaker = useDominantSpeaker()

  return (
    <View
      style={[
        // Has fixed max-width for when there're only two participants in stylesheets
        styles.container,
        isMinimized ? styles.containerMinimized : null,
        dominantSpeaker?.identity === participant?.identity &&
          styles.containerSelected,
        isHidden && styles.containerHidden
      ]}
    >
      {children}
      {!isVideoEnabled ? (
        <View style={styles.avatarContainer}>
          <User
            name={name!}
            size={isMinimized ? 'sm' : 'lg'}
            image={image}
            type={ptype!}
          />
        </View>
      ) : null}

      {/* Absolute positioned children */}
      <View style={styles.indicatorsContainer}>
        <NetworkQualityLevel participant={participant} />

        {/* muted indicator */}
        {!isAudioEnabled ? (
          <View style={styles.indicatorContainer}>
            <Icon
              name='mic-off'
              size={theme.size.F}
              color={theme.color.white.p56}
            />
          </View>
        ) : null}

        {/* speaking indicator */}
        {/* {dominantSpeaker?.identity === participant?.identity ? (
          <View
            style={[styles.indicatorContainer, styles.indicatorContainerActive]}
          >
            <Icon name='device-eq' size={theme.size.F} />
          </View>
        ) : null} */}

        {/* sharing screen indicator */}
        {/* <View style={styles.indicatorContainer}>
            <Icon name='screen-share-start' size={theme.size.F} />
          </View> */}
      </View>
      <View style={styles.nameContainer}>
        <Text type='body' weight='semibold' size='sm'>
          {name}
          {isLocalParticipant ? ' (You)' : null}
        </Text>
      </View>

      {/* Reconnecting */}
      {isLoading ? (
        <View style={styles.loaderContainer}>
          <Loader full size='large' />
        </View>
      ) : null}
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',

    backgroundColor: color.bg.v2,
    borderRadius: size.G,

    alignItems: 'center',
    justifyContent: 'center',

    overflow: 'hidden',
    maxWidth: '100%',
    maxHeight: '100%'
  },
  containerMinimized: {
    width: 122,
    height: 122
  },
  containerHidden: {
    display: 'none'
  },
  containerSelected: {
    borderWidth: size.A,
    borderColor: color.primary.solid
  },
  indicatorsContainer: {
    position: 'absolute',

    flexDirection: 'row',
    top: size.C,
    right: size.C,
    gap: size.B
  },
  indicatorContainer: {
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: color.black.p68,
    borderRadius: size.N
  },
  indicatorContainerActive: {
    backgroundColor: color.primary.solid
  },
  nameContainer: {
    position: 'absolute',
    left: size.E,
    bottom: size.D
  },
  loaderContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: color.white.p68
  },
  avatarContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))
