import { Participant } from 'twilio-video'
import {
  useIsTrackSwitchedOff,
  usePublications,
  useTrack,
  useParticipantIsReconnecting,
  useIsTrackEnabled
} from '@gopeerproject/web-kit'

import { ParticipantCard } from './ParticipantCard'

export const ParticipantInfo: React.FC<{
  children: React.ReactNode
  participant: Participant
  isSelected?: boolean
  isLocalParticipant?: boolean
  hideParticipant?: boolean
  isMinimized?: boolean
}> = ({
  children,
  participant,
  isLocalParticipant,
  hideParticipant,
  isMinimized
}) => {
  const publications = usePublications(participant)
  const audioPublication = publications.find((p) => p.kind === 'audio')
  const videoPublication = publications.find((p) =>
    p.trackName.includes('camera')
  )

  const isVideoEnabled = !!videoPublication
  const isScreenShareEnabled = publications.find((p) =>
    p.trackName.includes('screen')
  )

  const videoTrack = useTrack(videoPublication)
  // @ts-expect-error web-kit typing
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack)

  const audioTrack = useTrack(audioPublication)

  // @ts-expect-error web-kit typing
  const isAudioTrackEnabled = useIsTrackEnabled(audioTrack)

  const isParticipantReconnecting = useParticipantIsReconnecting(participant)

  return (
    <ParticipantCard
      isVideoEnabled={isVideoEnabled && !isVideoSwitchedOff}
      isAudioEnabled={isAudioTrackEnabled}
      isLoading={isParticipantReconnecting}
      isHidden={!!hideParticipant}
      isLocalParticipant={isLocalParticipant}
      isScreenSharing={!!isScreenShareEnabled}
      participant={participant}
      isMinimized={isMinimized}
    >
      {children}
    </ParticipantCard>
  )
}
