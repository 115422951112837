import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom'
import {
  setUser,
  captureException,
  reactRouterV6BrowserTracingIntegration,
  init,
  withScope,
  User
} from '@sentry/react'

export const Sentry = {
  init: () =>
    init({
      dsn: import.meta.env.VITE_SENTRY_URI,
      release: import.meta.env.VITE_RELEASE,
      integrations: [
        reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        })
      ],
      tracesSampleRate: 0.8,
      tracePropagationTargets: [
        'localhost',
        /^https:\/\/(?:api|next|dev)\.gopeer\.org/
      ],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: import.meta.env.MODE === 'production' ? 'prod' : 'dev'
    }),
  track: (
    error: Error,
    errorInfo?: Record<string, unknown>,
    fingerprint?: string
  ) => {
    withScope((scope) => {
      if (errorInfo) scope.setExtras(errorInfo)
      if (fingerprint) scope.setFingerprint(['{{default}}', fingerprint])
      captureException(error)
    })
  },
  captureException: captureException,
  authenticate: (user: User) => {
    setUser(user)
  }
}
