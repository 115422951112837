import JXG from 'jsxgraph'
import { fabric } from 'fabric'
import {
  createEmptyBoard,
  createFunctionGraph,
  createContainer
} from './helpers'

export const Graph = fabric.util.createClass(fabric.Rect, {
  type: 'Graph',

  initialize: function (options: any = {}) {
    this.callSuper('initialize', {
      width: 300,
      height: 300,
      left: 300,
      top: 300,
      fill: 'rgba(0,0,0,0)',
      rx: 8,
      ry: 8,
      ...options
    })

    this.setControlVisible('mtr', false)
    this.type = 'Graph'
    this.data = options.data
    this.id = this.id || `graph-${Date.now()}`

    this.on('modified', function (this: any, modified: any) {
      if (modified.data) {
        this.functionGraph.remove()
        this.functionGraph.removeAllTicks()
        this.functionGraph = null

        this.functionGraph = createFunctionGraph(this.board, modified.data)
      }

      if (
        modified.left ||
        modified.top ||
        modified.scaleX ||
        modified.scaleY ||
        modified.width ||
        modified.height ||
        modified.angle
      ) {
        this.positionElement()
      }
    })
    this.on('removed', function (this: any) {
      document.querySelector('.canvas-container')!.removeChild(this.container)
      // @ts-ignore
      JXG.JSXGraph._removeARIANodes(this.board)
    })
    this.on('moving', function (this: any) {
      this.positionElement()
    })
    this.on('scaling', function (this: any) {
      this.positionElement()
    })
  },
  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      type: 'Graph',
      data: this.get('data')
    })
  },
  _render: function (this: any, ctx: any) {
    this.callSuper('_render', ctx)

    if (!this.board) {
      this.container = createContainer({
        id: `fabric-${this.id}`,
        width: this.width - 10,
        height: this.height - 10
      })

      const { board } = createEmptyBoard(`fabric-${this.id}`)

      this.board = board
    }

    this.positionElement()
    this.functionGraph = createFunctionGraph(this.board, this.data)
  },
  positionElement: function () {
    const [canvasScaleX, _, __, canvasScaleY, left, top] = // eslint-disable-line
      this.canvas.viewportTransform
    const graph = this.container

    graph.style.left = this.left * canvasScaleX + left + 'px'
    graph.style.top = this.top * canvasScaleY + top + 'px'

    graph.style.width = this.get('width') + 'px'
    graph.style.height = this.get('height') + 'px'

    let transform = ''

    graph.style.width = this.get('width') * this.scaleX * canvasScaleX + 'px'
    graph.style.height = this.get('height') * this.scaleY * canvasScaleY + 'px'

    if (this.angle) transform += `rotate(${Math.round(this.angle)}deg) `

    if (transform) {
      graph.style['transform-origin'] = '0 0'
      graph.style.transform = transform
    }
  }
})
