export const redirectToCore = (pathname?: string) => {
  const CORE_URL = import.meta.env.VITE_CORE_URL
  if (!pathname) {
    window.location.replace(CORE_URL)
    return
  }

  if (pathname[0] !== '/') pathname = '/' + pathname

  window.location.replace(`${CORE_URL}${pathname}`)
}
