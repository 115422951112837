const TOOLS = {
  PENCIL: 'PENCIL',
  ERASER: 'ERASER',
  LINE: 'LINE',
  RECT: 'RECT',
  TRIANGLE: 'TRIANGLE',
  CIRCLE: 'CIRCLE',
  TEXT: 'TEXT',
  HAND: 'HAND',
  SELECT: 'SELECT',
  IMAGE: 'IMAGE',
  PATH: 'PATH',
  MATH: 'MATH'
} as const

const FILE_TYPES = {
  PDF: ['application/pdf'],
  IMAGES: ['image/jpeg', 'image/jpg', 'image/png']
} as const

const CONFIG = {
  zoom: 1,
  viewportTransform: [1, 0, 0, 1, 0, 0],
  lastPosX: 0,
  lastPosY: 0
} as const

const MEDIA_SCALE_HEIGHT = 640

const COLOR_PALETTE = [
  '(215,100,185)',
  '(120,159,230)',
  '(249,185,88)',
  '(135,191,79)',
  '(243,100,117)'
] as const

const LINE_STYLES = [
  { value: 'normal', label: 'Solid', icon: 'line' },
  {
    value: 'dashed',
    label: 'Dashed',
    icon: 'line-dashed'
  }
] as const

const LINE_WIDTHS = [
  { value: 2, label: 'Thin' },
  { value: 4, label: 'Normal' },
  { value: 6, label: 'Thick' }
] as const

export const SK = {
  TOOLS,
  CONFIG,
  COLOR_PALETTE,
  MEDIA_SCALE_HEIGHT,
  FILE_TYPES,
  LINE_STYLES,
  LINE_WIDTHS
} as const

export const Files = {
  MAX_FILE_SIZE: 1024 * 1024 * 25,
  PDF_WORKER: '//cdnjs.cloudflare.com/ajax/libs/pdf.js/2.12.313/pdf.worker.js'
} as const
