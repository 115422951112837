import type { fabric } from 'fabric'
import * as Y from 'yjs'
import { WebsocketProvider } from 'y-websocket'
import { YFabricBinding } from './YFabricBinding'
import { ObserverProxy } from './ObserverProxy'
import { ClassroomEnv } from '../contexts'
import { TProfile } from '@/types'

type YParticipant = {
  audio: boolean
  video: boolean
  screen: boolean
}

type YDocType = 'root' | 'sketch'

type YObservableFields = 'participants' | 'tabs' | 'awareness'

export const YKeys = {
  participants: 'participants',
  tabs: 'tabs',
  activeTab: 'activeTab',
  files: 'files'
}

export type TabType = 'sketch'

export type Tab = {
  id: string
  name: string
  type: TabType
  owner?: string
  accessedAt?: number
}

export class ClassroomManager {
  classroomId: string
  env: ClassroomEnv
  token: string
  user: Pick<TProfile, 'id' | 'name' | 'image' | 'type'>
  observers: Record<string, (() => void)[]>
  observerProxy: ObserverProxy

  doc: Y.Doc
  provider: WebsocketProvider & { id: string }
  participants: Y.Map<YParticipant>
  tabs: Y.Array<Y.Map<any>>
  activeTab: Y.Map<string>

  editors: {
    sketch?: {
      container: fabric.Canvas | null
      binding: YFabricBinding | null
      provider: WebsocketProvider | null
    }
  }

  files: Y.Map<Y.Map<any>>

  synced: boolean

  constructor({
    id,
    user,
    token,
    env
  }: {
    id: string
    user: TProfile
    env: ClassroomEnv
    token: string
  }) {
    this.log(`init(${JSON.stringify({ id, user, token })})`)
    // @ts-ignore
    window.cm = this

    this.classroomId = id
    this.env = env
    this.token = token
    this.user = {
      id: user.id,
      name: user.name,
      image: user.image,
      type: user.type
    }

    this.editors = {}
    this.observers = {}
    this.observerProxy = new ObserverProxy()

    this.synced = false
    this.doc = new Y.Doc()

    this.files = this.doc.getMap(YKeys.files)

    this.participants = this.doc.getMap<YParticipant>(YKeys.participants)
    this.initParticipant()

    this.tabs = this.doc.getArray(YKeys.tabs)
    this.activeTab = this.doc.getMap<string>(YKeys.activeTab)

    this.provider = this.createWebsocketProvider(
      this.getSocketUrl('root'),
      id,
      this.doc
    )
  }

  getSocketUrl = (type: YDocType) => {
    /**
     * Lyceum accepts:
     *   '/classroom/room/:userId' -- playground
     *   '/classroom/room/:apptId' -- root
     *   '/classroom/room/:apptId/file/:fileId' -- sketch or other file
     *
     * the last `id` ommited so that this works:
     * @ref https://github.com/yjs/y-websocket/blob/master/src/y-websocket.js#L191
     */
    const base = import.meta.env.VITE_LYCEUM_SOCKET_URL as string

    switch (type) {
      case 'root':
        return `${base}/classroom`
      case 'sketch':
        return `${base}/classroom/${this.classroomId}/file`
      default:
        throw new Error(`Unknown YDocType: ${type}`)
    }
  }

  createWebsocketProvider = (url: string, id: string, doc: Y.Doc) => {
    const provider = new WebsocketProvider(url, id, doc, {
      params: {
        token: this.token,
        env: this.env
      },
      disableBc: true
    })

    // @ts-ignore
    provider.id = id

    provider.awareness.setLocalStateField('user', {
      name: this.user.name,
      image: this.user.image,
      type: this.user.type,
      id: this.user.id
    })

    return provider as WebsocketProvider & { id: string }
  }

  destroyProvider = (provider: WebsocketProvider) => {
    // @ts-ignore
    this.log(`destroyProvider(${provider?.id})`)

    provider.awareness.setLocalState(null)
    provider.disconnect()
    provider.destroy()
  }

  destroyEditors = () => {
    if (this.editors.sketch?.provider)
      this.destroyProvider(this.editors.sketch.provider)
  }

  destroy = () => {
    this.unobserveAll()
    this.destroyParticipant()
    this.destroyEditors()
    this.destroyProvider(this.provider)
  }

  // Files
  openTab = (file: Tab) => {
    this.log(`openFile${JSON.stringify(file)}`)
    let tab = this.tabs.toArray().find((tab) => tab.get('id') === file.id)
    if (!tab) {
      tab = new Y.Map()
      tab.set('name', file.name)
      tab.set('id', file.id)
      tab.set('type', file.type)
      if (file.owner) tab.set('owner', file.owner)
      this.tabs.push([tab])
    }
    this.activateTab(file)
  }

  closeTab = (file: Pick<Tab, 'id'>) => {
    // if (file.type === 'finder')
    //   return this.activateFile({ type: file.type, id: '' })

    const tabs = this.tabs.toJSON()
    const index = tabs.findIndex((tab) => tab.id === file.id)
    const tab = tabs.find((tab) => tab.id === file.id)

    const activeTabId = this.activeTab.get('id')
    if (tab.id === activeTabId) {
      const next = tabs.reduce(
        (acc, v) =>
          v.accessedAt > acc.accessedAt && v.id !== tab.id ? v : acc,
        {
          accessedAt: 0
        }
      )
      this.activateTab({ id: next.id || '' })
    }

    this.tabs.delete(index, 1)
  }

  activateTab = (file: Pick<Tab, 'id'> | null) => {
    if (!file) {
      this.activeTab.set('id', '')
      this.destroyEditors()
      return
    }

    this.log(`activateFile(${JSON.stringify(file)})`)

    this.doc.transact(() => {
      this.activeTab.set('id', file.id)
      this.tabs.forEach((v) => {
        if (v.get('id') === file.id) v.set('accessedAt', Date.now())
      })
    })
  }

  getTabs = () => {
    this.log(`getTabs()`)
    return {
      tabs: this.tabs.toJSON() as Tab[],
      activeTabId: this.activeTab.get('id') || null
    }
  }

  // Files
  loadFile = (file: Pick<Tab, 'id' | 'type'>) => {
    this.log(`loadFile(${JSON.stringify(file)})`)

    let editor = this.editors[file.type]

    if (!editor) {
      this.attachEditor(file.type, null)
      editor = this.editors[file.type]
    }

    if (editor?.provider) this.destroyProvider(editor.provider)
    if (editor?.binding) editor.binding.destroy()

    switch (file.type) {
      case 'sketch':
        return this.loadSketch(file.id)
    }
  }

  loadSketch = (id: string) => {
    this.log(`loadSketch(${id})`)
    if (!this.files.has(id)) {
      const metadata = new Y.Map()
      const entry = new Y.Map()
      entry.set('metadata', metadata)
      this.files.set(id, entry)
    }

    const yDoc = new Y.Doc()
    const provider = this.createWebsocketProvider(
      this.getSocketUrl('sketch'),
      id,
      yDoc
    )
    const content = yDoc.getMap('')

    this.editors.sketch!.provider = provider
    this.editors.sketch!.binding = new YFabricBinding(
      content,
      this.editors.sketch!.container,
      provider.awareness,
      this.files.get(id)!.get('metadata'),
      this
    )
  }

  attachEditor = (type: 'sketch', editor: fabric.Canvas | null) => {
    this.log(`attachEditor(${type}, ${editor})`)
    if (this.editors[type]) {
      this.editors[type] = {
        ...this.editors[type],
        container: editor
      }
      return
    }

    this.editors[type] = { container: editor, binding: null, provider: null }

    return this.editors[type]
  }

  getEditor = (type: 'sketch') => {
    return this.editors[type]?.container
  }

  getBinding = (type: 'sketch') => {
    return this.editors[type]?.binding
  }

  observe = (field: YObservableFields, cb: () => void) => {
    this.log(`observe(${field}, cb)`)
    this.observerProxy.observe(field, cb)

    switch (field) {
      case 'tabs':
        this.tabs.observeDeep(cb)
        this.activeTab.observe(cb)
        break
      case 'participants':
        this.participants?.observe(cb)
        break
      case 'awareness':
        if (!this.observers['awareness']) this.observers['awareness'] = []
        this.observers['awareness'].push(cb)
        break
      default:
        break
    }
  }

  unobserve = (field: YObservableFields, cb: () => void) => {
    this.log(`unobserve(${field}, cb)`)
    if (!this.observerProxy.has(field, cb)) return

    this.observerProxy.unobserve(field, cb)

    switch (field) {
      case 'tabs':
        this.tabs.unobserve(cb)
        this.activeTab.unobserve(cb)
        break
      case 'participants':
        this.participants?.unobserve(cb)
        break
      case 'awareness':
        this.observers['awareness'] = this.observers['awareness'].filter(
          (ob) => ob !== cb
        )
        break
      default:
        break
    }
  }

  // observeMany = (observerMap) => {
  //   this.log(`observeMany(observerMap)`)
  //   Object.keys(observerMap).forEach((key) => {
  //     observerMap[key].forEach((observer) => {
  //       try {
  //         observer()
  //       } catch (e) {
  //         console.error(e)
  //       }
  //       this.observe(key, observer)
  //     })
  //   })
  // }

  unobserveAll = () => {
    this.log(`unobserveAll()`)
    const observerMap = this.observerProxy.getObservers()

    Object.keys(observerMap).forEach((key) => {
      observerMap[key].forEach((observer) => {
        this.unobserve(key as YObservableFields, observer)
      })
    })

    return observerMap
  }

  // isSynced = () => this.synced

  log(...args: any[]) {
    console.log(...args)
  }

  // Participants
  initParticipant = () => {
    this.participants.set(this.user.id, {
      audio: false,
      video: false,
      screen: false
    })
  }

  updateParticipant = (update: Partial<YParticipant>) => {
    const userId = this.user.id

    const prevState = this.participants.get(userId)!

    const state = {
      ...prevState,
      ...update
    }

    this.participants.set(userId, state)
  }

  // Currently redundant as it gets handled on the back-end
  destroyParticipant = () => {}
}
