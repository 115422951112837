import {
  GetRequestParams,
  get,
  PostRequestParams,
  post
  // DelRequestParams,
  // PatchRequestParams,
  // patch,
  // del
} from '@gopeerproject/chuck'
import { storage } from './storage'

export const formatUrl = (url: string) => {
  // Local testing setup
  if (
    import.meta.env.MODE === 'development' &&
    import.meta.env.VITE_LYCEUM_URL &&
    (url.includes('/classrooms/') ||
      url.includes('/recordings/') ||
      url.includes('/classroom_groups/'))
  ) {
    return `${import.meta.env.VITE_LYCEUM_URL}${url}`
  }

  return ('/api' + url).replace('/api', import.meta.env.VITE_API_URL)
}

const getToken = () => storage.getItem('gptk') ?? 'INVALID_TOKEN'

export const apiClient = {
  get: ({ url, ...args }: Omit<GetRequestParams, 'token'>) =>
    get({
      url: formatUrl(url),
      token: getToken(),
      ...args
    }),
  post: ({ url, ...args }: Omit<PostRequestParams, 'token'>) =>
    post({ url: formatUrl(url), token: getToken(), ...args })
  // patch: ({ url, ...args }: Omit<PatchRequestParams, 'token'>) =>
  //   patch({ url: formatUrl(url), ...args }),
  // del: ({ url, ...args }: Omit<DelRequestParams, 'token'>) =>
  //   del({ url: formatUrl(url), ...args })
}
