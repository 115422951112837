import { useClassroomState } from '../../contexts'
import { Conversation } from '../Conversation'
import { MemberControls } from '../MemberControls'
import { StandardControls } from './StandardControls'

export const Sidebar = () => {
  const { isSidebarOpen, sidebarPanel } = useClassroomState()

  switch (true) {
    case isSidebarOpen && sidebarPanel === 'conversation':
      return <Conversation />
    case isSidebarOpen && sidebarPanel === 'member_controls':
      return <MemberControls />
    case isSidebarOpen && sidebarPanel === 'standard_controls':
      return <StandardControls />
    default:
      return null
  }
}
