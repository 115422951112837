import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GlobalMessage, HeaderPlaceholder } from '@/components'
import { redirectToCore } from '@/utils'
import {
  Button,
  createStyleSheet,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'
import { useClassroomQueryById } from '../screens/classroom/hooks'
import { AppointmentStatus } from '@gopeerproject/chuck'

export const SessionClosed = () => {
  const styles = useStyleSheet(getStyles)
  const navigate = useNavigate()

  const { id } = useParams()
  const { data: classroom } = useClassroomQueryById(id!)

  useEffect(() => {
    if (
      classroom &&
      !(
        classroom.status === AppointmentStatus.Completed ||
        classroom.status === AppointmentStatus.Pending
      )
    ) {
      navigate(`/session/${classroom.id}`, { replace: true })
    }
  }, [navigate, classroom])

  return (
    <>
      <HeaderPlaceholder />
      <View style={styles.container}>
        <GlobalMessage
          emoji='🔒'
          title='Classroom is closed'
          description='This session has ended and the classroom is closed.'
        />
        <Button
          type='primary'
          centered
          size='lg'
          accent='default'
          onPress={() => {
            redirectToCore()
          }}
          text='Back to dashboard'
          style={styles.button}
        />
      </View>
    </>
  )
}

const getStyles = createStyleSheet(({ size }) => ({
  container: { flexGrow: 1, justifyContent: 'center', alignItems: 'center' },
  button: { marginTop: size.K }
}))
