import { useContext, Fragment } from 'react'
import {
  useStyleSheet,
  createStyleSheet,
  View,
  Text,
  useThemeContext,
  Focusable,
  Icon,
  IconName
} from '@gopeerproject/ui-kit'

import { SK } from '../constants'
import { EditorContext } from '../contexts'

export const ThicknessAndStylePopover = ({
  activeObjectProps = { strokeWidth: true, strokeStyle: true }
}) => {
  const {
    state: {
      line: { width, style }
    },
    setEditorState
  } = useContext(EditorContext)

  const setWidth = (width: EditorContext['line']['width']) =>
    setEditorState({ line: { width } })
  const setStyle = (style: EditorContext['line']['style']) =>
    setEditorState({ line: { style } })

  const styles = useStyleSheet(getStyles)
  return (
    <View style={styles.wrapper}>
      <View style={styles.header}>
        <Text size='md' type='headline'>
          Thickness and Style
        </Text>
      </View>
      <View style={styles.divider} />

      {activeObjectProps.strokeWidth ? (
        <>
          <ButtonGroup
            options={SK.LINE_WIDTHS.map(({ value }) => ({
              value,
              component: <Line width={value} />
            }))}
            onChange={setWidth as any}
            value={width}
          />
          <View style={styles.divider} />
        </>
      ) : null}

      {activeObjectProps.strokeStyle ? (
        <ButtonGroup
          options={SK.LINE_STYLES as any}
          onChange={setStyle as any}
          value={style}
        />
      ) : null}
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  wrapper: {},
  header: {
    paddingHorizontal: size.E,
    paddingTop: size.C,
    paddingBottom: size.E
  },
  divider: {
    backgroundColor: color.invert2.p4,
    height: 1,
    width: `calc(100% - ${2 * size.E}px)` as '100%',
    marginVertical: size.C,
    marginHorizontal: 'auto'
  },
  line: {
    height: size.I,
    backgroundColor: color.invert2.p100
  }
}))

const Line: React.FC<{ width: EditorContext['line']['width'] }> = ({
  width
}) => {
  const styles = useStyleSheet(getStyles)

  return <View style={[styles.line, { width, transform: 'rotate(45deg)' }]} />
}

const ButtonGroup: React.FC<{
  options: {
    value: string | number
    component?: React.ReactNode
    icon?: IconName
    label?: string
  }[]
  value: string | number
  onChange: (value: string | number) => void
}> = ({ options, value, onChange }) => {
  const styles = useStyleSheet(getButtonGroupStyles)
  const { color, size } = useThemeContext()
  return (
    <View style={styles.wrapper}>
      {options.map((option, i) => {
        return (
          <Fragment key={option.value}>
            <Focusable
              style={[
                styles.item,
                value === option.value && styles.item__active
              ]}
              hoverStyle={styles.item__hover}
              onPress={() => onChange(option.value)}
            >
              {option.component ? (
                option.component
              ) : (
                <>
                  <Icon
                    color={color.invert2.p100}
                    size={size.I}
                    name={option.icon!}
                  />

                  <Text
                    size='md'
                    type='body'
                    style={styles.itemTitle}
                    weight='regular'
                  >
                    {option.label}
                  </Text>
                </>
              )}
            </Focusable>

            {i < options.length - 1 && <View style={styles.divider} />}
          </Fragment>
        )
      })}
    </View>
  )
}

const getButtonGroupStyles = createStyleSheet(({ size, color }) => ({
  wrapper: {
    flexDirection: 'row',
    backgroundColor: color.bg.v2,

    borderWidth: 1,
    borderColor: color.invert2.p4,
    borderRadius: size.G,

    padding: size.B,
    alignItems: 'center'
  },
  item: {
    borderRadius: size.E,
    borderWidth: 1,
    borderColor: 'transparent',

    flexDirection: 'row',
    justifyContent: 'center',
    gap: size.C,
    flexGrow: 1,
    flexShrink: 0,
    // flexBasis: '100px',

    paddingVertical: size.D,
    paddingHorizontal: size.F
  },
  item__hover: {
    backgroundColor: color.invert2.p10
  },
  item__active: {
    borderColor: color.invert2.p4,
    backgroundColor: color.bg.v3
  },
  itemIcon: {},
  itemTitle: {},
  divider: {
    width: 1,
    height: size.J,
    marginHorizontal: size.B,
    backgroundColor: color.invert2.p4
  }
}))
