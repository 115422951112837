import ReactDOM from 'react-dom/client'
import { fabric } from 'fabric'
import { StaticMathField } from 'react-mathquill'
import { createContainer } from './helpers'

export const Formula = fabric.util.createClass(fabric.Object, {
  type: 'Formula',
  initialize: function (options: any = {}) {
    this.type = 'Formula'
    this.data = options.data
    this.id = this.id || `formula-${Date.now()}`
    this.container = createContainer({ id: this.id })
    this.root = null

    this.callSuper('initialize', {
      left: 150,
      top: 150,
      stroke: 'rgba(249,185,88, 1)',
      ...options
    })

    this.setControlsVisibility({
      bl: true,
      br: true,
      mb: false,
      ml: false,
      mr: false,
      mt: false,
      tl: true,
      tr: true,
      mtr: true
    })

    this.updateElementDimensions()
    this.on('removed', function (this: any) {
      document.querySelector('.canvas-container')!.removeChild(this.container)
    })
    this.on('moving', function (this: any) {
      this.positionElement()
    })
    this.on('modified', function (this: any, modified: any) {
      console.log('modified', modified)
      if (modified.data) {
        this.renderElement(modified.data)
        this.updateElementDimensions()
      }
    })
    this.on('scaling', function (this: any) {
      this.renderElement()
    })
  },
  toObject: function () {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      type: 'Formula',
      data: this.get('data')
    })
  },
  _render: function (this: any, ctx: any) {
    this.callSuper('_render', ctx)

    this.renderElement()
  },
  updateElementDimensions: function () {
    const timeoutID = setTimeout(() => {
      const formulaNode = document.querySelector(`[data-id="${this.id}"]`)!
      const { width, height } = formulaNode.getBoundingClientRect()

      this.set({ width, height })
      this.canvas.renderAll()
      clearTimeout(timeoutID)
    }, 0)
  },
  renderElement: function (this: any, data: any) {
    if (!this.root) this.root = ReactDOM.createRoot(this.container)

    this.root.render(
      <StaticMathField
        data-id={this.id}
        style={{
          color: this.stroke,
          transform: `scale(${
            this.scaleX * this.canvas.viewportTransform[0]
          }, ${this.scaleY * this.canvas.viewportTransform[3]})`,
          transformOrigin: 'left top'
        }}
      >
        {data || this.data}
      </StaticMathField>
    )
    this.positionElement()
  },
  positionElement: function (this: any) {
    const [canvasScaleX, _, __, canvasScaleY, left, top] = // eslint-disable-line
      this.canvas.viewportTransform
    const graph = this.container

    graph.style.left = this.left * canvasScaleX + left + 'px'
    graph.style.top = this.top * canvasScaleY + top + 'px'

    graph.style.width = this.get('width') + 'px'
    graph.style.height = this.get('height') + 'px'

    let transform = ''

    graph.style.width = this.get('width') * this.scaleX * canvasScaleX + 'px'
    graph.style.height = this.get('height') * this.scaleY * canvasScaleY + 'px'

    if (this.angle) transform += `rotate(${Math.round(this.angle)}deg) `

    if (transform) {
      graph.style['transform-origin'] = '0 0'
      graph.style.transform = transform
    }
  }
})
