import React from 'react'

import {
  View,
  Text,
  useStyleSheet,
  createStyleSheet,
  Header,
  Input,
  Button
} from '@gopeerproject/ui-kit'

import { useClassroomQuery, useClassroomUpdate } from '../../hooks'
import { useClassroomDispatch } from '../../contexts'

export const StandardControls = () => {
  const styles = useStyleSheet(getStyles)
  const dispatch = useClassroomDispatch()

  const { data } = useClassroomQuery()
  const { mutate: updateClassroom, isLoading: isUpdating } =
    useClassroomUpdate()

  const [localStandard, setLocalStandard] = React.useState(() =>
    data?.standard !== undefined ? data.standard : ''
  )

  return (
    <View style={styles.container}>
      <View style={styles.headerContainer}>
        <Header
          size='md'
          title='Standard'
          action='custom'
          controls={[
            {
              icon: 'dismiss',
              onPress: () => {
                dispatch({
                  type: 'toggle_sidebar',
                  payload: 'standard_controls'
                })
              }
            }
          ]}
          style={styles.header}
        />
      </View>
      <View style={styles.sections}>
        <Text type='body' size='md' weight='semibold' style={styles.inputLabel}>
          Enter Standard
        </Text>

        <Input
          style={styles.input}
          value={localStandard}
          placeholder='e.g. AG1.3QV'
          onChange={setLocalStandard}
          hideDismiss
          action={
            localStandard === (data!.standard || '') ? null : (
              <View style={styles.inputAction}>
                <Button
                  size='xs'
                  type='secondary'
                  accent='default'
                  text='Save'
                  leftIcon='checkmark'
                  disabled={isUpdating}
                  isLoading={isUpdating}
                  onPress={() => {
                    // @ts-expect-error
                    updateClassroom({ standard: localStandard })
                  }}
                />
              </View>
            )
          }
        />
        <Text type='body' size='md' weight='regular' style={styles.hint}>
          An academic standard that&apos;s used across school districts to
          reference specific learning topics, that was covered in a session.
        </Text>
      </View>
    </View>
  )
}

const getStyles = createStyleSheet(({ color, size }) => ({
  container: {
    width: 360,
    backgroundColor: color.bg.v1
  },
  headerContainer: {
    marginBottom: size.G,
    paddingHorizontal: size.E
  },
  header: {
    borderBottomColor: color.invert2.p4,
    borderBottomWidth: 1
  },
  sections: {
    paddingHorizontal: size.F + size.E
  },
  input: {},
  inputAction: {
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    paddingRight: size.F - size.C
  },
  inputLabel: {
    marginBottom: size.C
  },
  hint: {
    color: color.invert2.p56,
    marginTop: size.C
  }
}))
