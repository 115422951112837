export const assert = <T>(value: T | undefined | null, message: string): T => {
  if (value === undefined || value === null) {
    throw new Error(message)
  }
  return value as T
}

export const assertContext = <T>(
  context: T,
  hook: string,
  provider: string
) => {
  assert(context, `${hook} must be used within the ${provider}`)
  return context
}
