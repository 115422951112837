// @ts-nocheck
import React, { useRef, useEffect } from 'react'
import type { fabric } from 'fabric'
import { useShortcutsContext } from './contexts'
import { useClassroomManagerRef } from '../../contexts'

export const SketchShortcuts: React.FC<{ canvas: fabric.Canvas }> = ({
  canvas
}) => {
  const { registerShortcut } = useShortcutsContext()
  const clipboard = useRef()
  const classroomManagerRef = useClassroomManagerRef()

  const erase = () => {
    const activeObjects = canvas.getActiveObjects()

    if (activeObjects.length) {
      const isInEditingState = activeObjects.some((obj) => obj.isEditing)

      activeObjects
        .filter((object) => !object.isEditing)
        .forEach((obj) => canvas.remove(obj))

      if (!isInEditingState) canvas.discardActiveObject()
      return
    }
  }

  const redo = () => {
    const binding = classroomManagerRef.current!.getBinding('sketch')

    if (canvas.getActiveObject()?.isEditing) return
    if (binding) binding.redo()
  }

  const undo = () => {
    const binding = classroomManagerRef.current!.getBinding('sketch')

    if (canvas.getActiveObject()?.isEditing) return
    if (binding) binding.undo()
  }

  const copy = () => {
    const active = canvas.getActiveObject()

    if (active.isEditing) {
      const selectedText = active.text.slice(
        active.selectionStart,
        active.selectionEnd
      )

      active.clone((cloned) => {
        cloned.text = selectedText
        clipboard.current = cloned
      })
      return
    }

    if (active)
      active.clone((cloned) => {
        clipboard.current = cloned
      })
  }

  const paste = () => {
    if (!clipboard.current) return
    if (canvas.getActiveObject()?.isEditing) return

    clipboard.current.clone((cloned) => {
      canvas.discardActiveObject()

      cloned.set({
        id: `${cloned.type}-${Date.now()}`,
        left: cloned.left + 10,
        top: cloned.top + 10
      })

      if (cloned.type === 'activeSelection') {
        cloned.canvas = canvas
        cloned.forEachObject((obj) => canvas.add(obj))
        cloned.setCoords()
      } else {
        canvas.add(cloned)
      }

      clipboard.current = cloned
      clipboard.current.top += 10
      clipboard.current.left += 10
      canvas.setActiveObject(cloned)
      canvas.requestRenderAll()
    })
  }

  const cut = () => {
    copy()
    erase()
  }

  // const openFormulaGraphModal = () => {
  //   open({ name: 'formula-graph-modal' })
  // }

  useEffect(() => {
    if (!canvas) return

    registerShortcut('Backspace', erase)
    registerShortcut('CtrlOrCmd+Shift+z', redo)
    registerShortcut('CtrlOrCmd+z', undo)
    registerShortcut('CtrlOrCmd+c', copy)
    registerShortcut('CtrlOrCmd+v', paste)
    registerShortcut('CtrlOrCmd+x', cut)
    // registerShortcut('CtrlOrCmd+Shift+o', openFormulaGraphModal)
  }, [canvas]) // eslint-disable-line

  return null
}
