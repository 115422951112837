import { createStyleSheet, useStyleSheet, View } from '@gopeerproject/ui-kit'
import { useMediaContext } from '@gopeerproject/web-kit'
import { AudioInputList } from './AudioInputList'
import { AudioLevelIndicator } from './AudioLevelIndicator'

export const AudioInputSetting = () => {
  const styles = useStyleSheet(getStyles)
  const { localTracks } = useMediaContext()
  const localAudioTrack = localTracks.find((track) => track.kind === 'audio')

  return (
    <View style={styles.container}>
      <AudioInputList />
      <View style={styles.levelIndicatorContainer}>
        <AudioLevelIndicator audioTrack={localAudioTrack} />
      </View>
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: { borderRadius: size.C, backgroundColor: color.invert2.p4 },
  levelIndicatorContainer: { padding: size.F }
}))
