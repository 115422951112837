import type { Participant as TParticipant } from 'twilio-video'

import { ParticipantTracks } from './ParticipantTracks'
import { ParticipantInfo } from './ParticipantInfo'

export const Participant: React.FC<{
  participant: TParticipant
  isLocalParticipant?: boolean
  videoOnly?: boolean
  enableScreenShare?: boolean
  onClick?: () => void
  isSelected?: boolean
  hideParticipant?: boolean
  isMinimized?: boolean
}> = ({
  participant,
  videoOnly,
  enableScreenShare,
  isSelected,
  isLocalParticipant,
  hideParticipant,
  isMinimized
}) => {
  return (
    <ParticipantInfo
      participant={participant}
      isSelected={isSelected}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
      isMinimized={isMinimized}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  )
}
