import { ShortcutsProvider, EditorContextProvider } from './contexts'
import { InstrumentToolbar, UtilityToolbar } from './Toolbar'
import { Frame } from './Frame'
import { Editor } from './Editor'
import { Cursors } from './Cursor'

import { useState, useCallback } from 'react'
import { useClassroomManagerRef, useClassroomState } from '../../contexts'

export const SketchPad: React.FC = () => {
  const classroomManagerRef = useClassroomManagerRef()
  const { activeTabId } = useClassroomState()
  const [, setState] = useState({})

  const handleChange = useCallback((patch: any) => {
    setState((prevState) => ({ ...prevState, ...patch }))
  }, [])

  const onZoomIn = useCallback(() => {
    const editor = classroomManagerRef.current!.getEditor('sketch')
    if (!editor) return

    let zoom = editor.getZoom() + 0.1
    zoom = zoom > 20 ? 20 : zoom
    editor.zoomToPoint(
      { x: editor.width! / 2, y: editor.height! / 2 } as fabric.Point,
      zoom
    )
    editor.fire('position:updated')
  }, [classroomManagerRef])

  const onZoomOut = useCallback(() => {
    const editor = classroomManagerRef.current!.getEditor('sketch')
    if (!editor) return

    let zoom = editor.getZoom() - 0.1
    zoom = zoom < 0.1 ? 0.1 : zoom
    editor.zoomToPoint(
      { x: editor.width! / 2, y: editor.height! / 2 } as fabric.Point,
      zoom
    )
    editor.fire('position:updated')
  }, [classroomManagerRef])

  return (
    <EditorContextProvider>
      <ShortcutsProvider>
        <Frame
          leftToolbar={
            <>
              <InstrumentToolbar />
              <UtilityToolbar
                onUndo={() =>
                  classroomManagerRef.current!.getBinding('sketch')?.undo()
                }
                onRedo={() =>
                  classroomManagerRef.current!.getBinding('sketch')?.redo()
                }
                onZoomIn={onZoomIn}
                onZoomOut={onZoomOut}
              />
            </>
          }
        >
          <Editor
            handleChange={handleChange}
            active={activeTabId}
            attachEditor={(editorInstance: fabric.Canvas | null) => {
              classroomManagerRef.current!.attachEditor(
                'sketch',
                editorInstance
              )
            }}
            loadFile={() => {
              if (!activeTabId) return

              classroomManagerRef.current!.loadFile({
                id: activeTabId,
                type: 'sketch'
              })
            }}
          />

          <Cursors />
        </Frame>
      </ShortcutsProvider>
    </EditorContextProvider>
  )
}
