import { Fragment, memo } from 'react'
import {
  createStyleSheet,
  Divider,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'
import { useAudioLevelIndicator } from '@gopeerproject/web-kit'
import type { AudioTrack } from 'twilio-video'

export const AudioLevelIndicator: React.FC<{ audioTrack?: AudioTrack }> = memo(
  ({ audioTrack }) => {
    const styles = useStyleSheet(getStyles)
    const { level } = useAudioLevelIndicator({ audioTrack })

    // className='gp-AudioLevelIndicator'
    return (
      <View style={styles.container}>
        <View style={[styles.level, { width: `${level * 10}%` }]} />
        {new Array(10).fill(void 0).map((_, i, arr) => (
          <Fragment key={i}>
            <View style={styles.gap} />
            {i < arr.length - 1 && (
              <Divider type='vertical' style={styles.divider} />
            )}
          </Fragment>
        ))}
      </View>
    )
  }
)

AudioLevelIndicator.displayName = 'AudioLevelIndicator'

const getStyles = createStyleSheet(({ color, size }) => ({
  container: {
    width: '100%',
    height: size.E,
    borderRadius: size.C,
    overflow: 'hidden',
    backgroundColor: color.invert2.p10,
    flexDirection: 'row'
  },
  level: {
    backgroundColor: color.primary.solid,
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    transition: 'width 0.25s'
  },
  gap: { flex: 1 },
  divider: {
    flexBasis: 0,
    borderRightWidth: size.A,
    borderRightColor: color.invert2.p4,
    backgroundColor: color.bg.v1
  }
}))
