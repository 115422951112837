export class ObserverProxy {
  private observers: Map<string, Set<() => void>>

  static create() {
    return new ObserverProxy()
  }
  constructor() {
    this.observers = new Map()
  }

  observe(key: string, callback: () => void) {
    if (!this.observers.has(key)) {
      this.observers.set(key, new Set())
    }

    const observerSet = this.observers.get(key)
    observerSet!.add(callback)
  }

  unobserve(key: string, callback: () => void) {
    if (!this.observers.has(key)) return

    const observerSet = this.observers.get(key)
    observerSet!.delete(callback)
  }

  getObservers(): Record<string, (() => void)[]> {
    return Array.from(this.observers.entries())
      .map(([key, observerSet]) => {
        return { key, value: Array.from(observerSet) }
      })
      .reduce(
        (acc, { key, value: observers }) => ({ ...acc, [key]: observers }),
        {}
      )
  }

  has(key: string, callback: () => void) {
    if (!this.observers.has(key)) return false
    const observerSet = this.observers.get(key)
    return observerSet!.has(callback)
  }

  destroy() {
    this.observers = new Map()
  }
}
