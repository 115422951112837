import { createContext, useContext, useMemo, useState } from 'react'
import { assertContext as assert } from '@/utils'

const classroomEnvContext = createContext<ClassroomEnv | undefined>(undefined)
const classroomIdContext = createContext<string | undefined>(undefined)

export enum ClassroomEnv {
  PLAYGROUND = 'PLAYGROUND',
  CLASSROOM = 'CLASSROOM',
  RECORDING = 'RECORDING',
  SKETCHPAD = 'SKETCHPAD'
}

export const ClassroomEnvProvider: React.FC<{
  env: ClassroomEnv
  id: string
  children: React.ReactNode
}> = ({ env, children, id }) => {
  const [idState] = useState(() => id)
  return (
    <classroomEnvContext.Provider value={env}>
      <classroomIdContext.Provider value={idState}>
        {children}
      </classroomIdContext.Provider>
    </classroomEnvContext.Provider>
  )
}

export const useClassroomEnv = () => {
  const env = assert(
    useContext(classroomEnvContext)!,
    'useClassroomEnv',
    'ClassroomEnvProvider'
  )

  const id = assert(
    useContext(classroomIdContext)!,
    'useClassroomEnv',
    'ClassroomIdProvider'
  )

  return useMemo(() => {
    return {
      env,
      id
    }
  }, [env, id])
}
