import React from 'react'
import {
  createStyleSheet,
  View,
  useStyleSheet,
  Text,
  Icon,
  useThemeContext
} from '@gopeerproject/ui-kit'

export const Uploader = () => {
  const styles = useStyleSheet(getStyles)
  const { size } = useThemeContext()

  return (
    <View style={styles.container}>
      <Icon
        name='upload-files'
        color={(color) => color.primary.solid}
        size={size.N}
      />
      <Text type='heading' size='h6' style={styles.text}>
        Drop files here to upload
      </Text>
    </View>
  )
}

const getStyles = createStyleSheet(({ color, size }) => ({
  container: {
    flex: 1,
    backgroundColor: color.bg.v1,
    borderWidth: size.A,
    borderColor: color.invert2.p10,
    borderStyle: 'dashed',
    borderRadius: size.G,
    alignItems: 'center',
    justifyContent: 'center'
  },
  text: {
    marginTop: size.C
  }
}))
