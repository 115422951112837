import { Participant } from 'twilio-video'
import { useParticipantNetworkQualityLevel } from '@gopeerproject/web-kit'
import { createStyleSheet, useStyleSheet, View } from '@gopeerproject/ui-kit'

const LEVELS = [1, 2, 3, 4, 5] as const

const getColor = (level: number): 'neutral' | 'warning' | 'danger' => {
  if (level <= 1) return 'danger'
  if (level <= 3) return 'warning'
  return 'neutral'
}

export const NetworkQualityLevel: React.FC<{ participant: Participant }> = ({
  participant
}) => {
  const styles = useStyleSheet(getStyles)
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant)

  if (networkQualityLevel === null) return null

  const color = getColor(networkQualityLevel)

  return (
    <View style={styles.background}>
      <View style={styles.container}>
        {LEVELS.map((level) => (
          <View
            key={level}
            style={[
              { height: level * 2 },
              styles.bar,
              styles[level <= networkQualityLevel ? color : 'disabled']
            ]}
          />
        ))}
      </View>
    </View>
  )
}

const getStyles = createStyleSheet(({ color, size }) => ({
  background: {
    width: 25,
    height: 25,
    alignItems: 'center',
    justifyContent: 'center',

    backgroundColor: color.black.p68,
    borderRadius: size.N
  },
  container: {
    flexDirection: 'row',
    alignItems: 'flex-end',
    gap: 1,
    marginRight: 1
  },
  bar: { width: size.A, borderRadius: 1, transition: 'background-color .25s' },
  disabled: {
    backgroundColor: color.white.p16
  },
  neutral: {
    backgroundColor: color.white.p56
  },
  warning: {
    backgroundColor: color.warning.solid
  },
  danger: {
    backgroundColor: color.negative.solid
  }
}))
