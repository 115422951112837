import { createContext, useCallback, useContext, useMemo } from 'react'
import { assertContext as assert, isClient } from '@/utils'

import { ClassroomEnv, useClassroomEnv } from './ClasssroomEnvContext'
import { useAuth } from '@/contexts'
import { useClassroomQuery } from '../hooks'

export enum Feature {
  TOOL_PANEL_TOGGLE = 'tool_panel_toggle', // sketch/notes/code/files toggles in header, or close button
  TOOL_NEW_FILE = 'tool_new_file', // new file button in all tools empty panel and in the tabbar
  TOOL_SWITCH_FILE = 'tool_switch_tab', // switch between tabs in the tabbar, close current file, open different file
  TOOL_RENAME_FILE = 'tool_rename_file', // rename file in the tabbar
  TOOL_EDIT = 'tool_edit', // edit (draw in sketch, type in note/code) permission tools

  SKETCH_ERASER = 'sketch_eraser',
  SKETCH_UNDO_REDO = 'sketch_undo_redo',

  SCREEN_SHARE_TOGGLE = 'screen_share',
  VIDEO_UPLOAD_CUSTOM_BACKGROUNDS = 'video_upload_custom_backgrounds',

  FULL_SCREEN_TOGGLE = 'full_screen'
}

// This should really work based on platform stuff and maybe even come from DB but this hook should be a good start
// In case certain env/apptType/ptype combinations are not defined, the default value is ALLOWED
const ACL = {
  [ClassroomEnv.CLASSROOM]: {
    group: {
      client: {
        [Feature.TOOL_PANEL_TOGGLE]: false,
        [Feature.TOOL_NEW_FILE]: false,
        [Feature.TOOL_SWITCH_FILE]: false,
        [Feature.TOOL_RENAME_FILE]: false,
        [Feature.TOOL_EDIT]: true,
        [Feature.SCREEN_SHARE_TOGGLE]: false,
        [Feature.SKETCH_ERASER]: false,
        [Feature.SKETCH_UNDO_REDO]: false,
        [Feature.VIDEO_UPLOAD_CUSTOM_BACKGROUNDS]: false,
        [Feature.FULL_SCREEN_TOGGLE]: false
      }
    }
  }
}

const classroomPermissionsContext = createContext<{
  checkPermission: (feature: Feature, env?: ClassroomEnv) => boolean
}>({ checkPermission: () => true })

export const ClassroomPermissionsProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const { env } = useClassroomEnv()
  const { data: classroom } = useClassroomQuery()
  const { profile } = useAuth()

  const apptType = classroom!.type
  const ptype = profile!.type

  const checkPermission = useCallback(
    (feature: Feature, _env = env) => {
      /* @ts-expect-error */
      const envLevel = ACL[_env]
      if (!envLevel) return true

      const apptTypeLevel = envLevel[apptType]
      if (!apptTypeLevel) return true

      const featureListLevel =
        apptTypeLevel[isClient(ptype) ? 'client' : 'manager']
      if (!featureListLevel) return true

      return featureListLevel[feature] ?? true
    },
    [apptType, ptype, env]
  )

  const value = useMemo(() => {
    return {
      checkPermission
    }
  }, [checkPermission])

  return (
    <classroomPermissionsContext.Provider value={value}>
      {children}
    </classroomPermissionsContext.Provider>
  )
}

export const useClassroomPermissions = () => {
  return assert(
    useContext(classroomPermissionsContext),
    'useClassroomPermissions',
    'ClassroomPermissionsProvider'
  )
}
