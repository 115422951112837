import {
  Button,
  createStyleSheet,
  IconButton,
  Text,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'

import { TToast, useToastDispatch, useToasts } from '../../contexts'

export const ToastsContainer = () => {
  const styles = useStyleSheet(getStyles)
  const toasts = useToasts()
  const { dismissToast } = useToastDispatch()
  return (
    <View style={styles.toastsContainer}>
      {toasts.map((toast) => (
        <Toast
          key={toast.id}
          icon={toast.icon}
          title={toast.title}
          subtitle={toast.subtitle}
          accent={toast.accent}
          onDismiss={() => {
            dismissToast(toast.id)
          }}
        />
      ))}
    </View>
  )
}

export const Toast: React.FC<
  Omit<TToast, 'id'> & { onDismiss: () => void }
> = ({ accent, icon, onDismiss, title, subtitle }) => {
  const styles = useStyleSheet(getStyles)

  return (
    <View style={styles.toast}>
      <IconButton
        type='secondary'
        accent={accent}
        value={icon}
        size='sm'
        onPress={() => {}}
        style={styles.toastIcon}
      />
      <View style={styles.toastText}>
        <Text type='body' size='md' weight='semibold'>
          {title}
        </Text>
        {subtitle ? (
          <Text
            type='body'
            size='md'
            weight='regular'
            style={styles.toastTextSubtitle}
          >
            {subtitle}
          </Text>
        ) : null}
      </View>
      <Button
        size='md'
        type='tertiary'
        accent='default'
        onPress={onDismiss}
        text='Dismiss'
        style={styles.toastDismiss}
      />
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  toastsContainer: {
    position: 'absolute',
    top: size.O,
    left: `calc(50% - 172px)` as 'auto',
    gap: size.C
  },

  toast: {
    flexDirection: 'row',
    alignItems: 'center',

    width: 520,
    gap: size.E,

    padding: size.E,

    backgroundColor: color.bg.v3,
    borderWidth: 1,
    borderColor: color.invert2.p4,
    borderRadius: size.G
  },

  // toastIconContainer: {
  //   width: 39,
  //   height: 39,
  //   borderWidth: 1,
  //   borderRadius: size.P
  // },
  toastIcon: {
    pointerEvents: 'none'
  },
  toastText: { flexGrow: 1 },
  toastTextSubtitle: { color: color.invert2.p56 },
  toastDismiss: {}
}))
