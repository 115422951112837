import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useChuckClientContext, useAPI } from '@gopeerproject/chuck'

export type TFile = {
  id: string
  name: string
  type: 'sketch'
}

export const useFileCreate = () => {
  const { post } = useAPI()
  const { client } = useChuckClientContext()
  const queryClient = useQueryClient()

  return useMutation(
    (body: { name: string; type: 'sketch'; participants: string[] }) => {
      return post({ url: '/files/dynamic', data: body }).then(
        (res) => res.data as TFile
      )
    },
    {
      onSuccess: (file) => {
        queryClient.setQueryData(['files'], (files: any) => {
          if (!files) return [file]

          return [file].concat(files)
        })
      },
      onError: (err) => {
        client.captureException(err)
      }
    }
  )
}

export const useFileQuery = (id: string) => {
  const { get } = useAPI()
  const queryClient = useQueryClient()

  return useQuery(
    ['files', id],
    () => get({ url: `/files/${id}` }).then((res) => res.data as TFile),
    {
      onSuccess: (file) => {
        queryClient.setQueryData(['files'], (files: any) => {
          if (!files) return [file]

          const index = files.findIndex((file: any) => file.id === id)
          if (index === -1) return files.concat([file])
          const prevFile = files[index]

          return files
            .slice(0, index)
            .concat([{ ...prevFile, ...file }])
            .concat(files.slice(index + 1))
        })
      },
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )
}

export const useFilesQuery = () => {
  const { get } = useAPI()

  return useQuery(
    ['files'],
    () => get({ url: '/files' }).then((res) => res.data as TFile[]),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false
    }
  )
}
