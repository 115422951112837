import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { GlobalMessage, HeaderPlaceholder } from '@/components'
import { redirectToCore } from '@/utils'
import {
  Button,
  createStyleSheet,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'
import { useClassroomQueryById } from '../screens/classroom/hooks'
import { AppointmentStatus } from '@gopeerproject/chuck'
import { useMemberControlsQueryById } from '../screens/classroom/hooks'
import { WaitingRoomStatus } from '@/data'

export const SessionAdmissionRejected = () => {
  const styles = useStyleSheet(getStyles)
  const navigate = useNavigate()

  const { id } = useParams()
  const { data: classroom } = useClassroomQueryById(id!)
  const { data: memberControls } = useMemberControlsQueryById(id!)

  useEffect(() => {
    if (
      (classroom && classroom.status !== AppointmentStatus.InProgress) ||
      (classroom &&
        memberControls &&
        memberControls.status !== WaitingRoomStatus.REJECTED)
    ) {
      navigate(`/session/${classroom.id}`, { replace: true })
    }
  }, [navigate, classroom, memberControls])

  return (
    <>
      <HeaderPlaceholder />
      <View style={styles.container}>
        <GlobalMessage
          emoji='🔒'
          title='Your admission to the classroom has been rejected'
          description='If there was a mistake, please contact your tutor or customer support to request admission again.'
        />
        <Button
          type='primary'
          centered
          size='lg'
          accent='default'
          onPress={() => {
            redirectToCore()
          }}
          text='Back to dashboard'
          style={styles.button}
        />
      </View>
    </>
  )
}

const getStyles = createStyleSheet(({ size }) => ({
  container: { flexGrow: 1, justifyContent: 'center', alignItems: 'center' },
  button: { marginTop: size.K }
}))
