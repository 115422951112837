import { AppointmentStatus } from '@gopeerproject/chuck'
import { ClassroomScreen } from '@/screens'
import { queryClient } from '@/utils'
import {
  queryClassroom,
  openClassroom,
  queryMemberControls,
  WaitingRoomStatus
} from '@/data'

import {
  Await,
  defer,
  useLoaderData,
  useParams,
  type LoaderFunction
} from 'react-router-dom'
import {
  ClassroomEnv,
  ClassroomEnvProvider,
  ClassroomManagerRefProvider,
  ClassroomPermissionsProvider,
  ClassroomProvider,
  MediaProvider,
  ToastsProvider
} from '../screens/classroom/contexts'
import { Suspense } from 'react'
import { Loader } from '@gopeerproject/ui-kit'
import { CacheKeys } from '@/constants'
import { GlobalError } from '../components/GlobalError'

const loadData = async ({ params }: { params: { id: string } }) => {
  const { id } = params
  const classroomId = id!
  const classroom = await queryClassroom(classroomId)

  switch (classroom.status) {
    case AppointmentStatus.InProgress: {
      if (!classroom.twilioRoom?.sid) {
        await openClassroom(classroomId)
        await queryClient.refetchQueries([CacheKeys.classrooms, classroomId])
      }

      const memberControls = await queryMemberControls(classroomId)

      if (memberControls.status === WaitingRoomStatus.REJECTED) {
        return Promise.reject({ redirect: 'rejected' })
      }
      return {}
    }
    case AppointmentStatus.Confirmed:
      return Promise.reject({ redirect: 'not_in_progress' })

    case AppointmentStatus.Pending:
    case AppointmentStatus.Completed:
      return Promise.reject({ redirect: 'closed' })
    default:
      return Promise.reject(new Error('invalid appointment type'))
  }
}

export const loader: LoaderFunction = async ({ params }) => {
  return defer({ result: loadData({ params } as { params: { id: string } }) })
}

export const Session = () => {
  const { id } = useParams()
  const { result } = useLoaderData() as any

  return (
    <Suspense fallback={<Loader full />}>
      <Await resolve={result} errorElement={<GlobalError />}>
        {() => (
          <ClassroomManagerRefProvider>
            <ClassroomEnvProvider env={ClassroomEnv.CLASSROOM} id={id!}>
              <ClassroomProvider>
                <MediaProvider>
                  <ClassroomPermissionsProvider>
                    <ToastsProvider>
                      <ClassroomScreen />
                    </ToastsProvider>
                  </ClassroomPermissionsProvider>
                </MediaProvider>
              </ClassroomProvider>
            </ClassroomEnvProvider>
          </ClassroomManagerRefProvider>
        )}
      </Await>
    </Suspense>
  )
}
