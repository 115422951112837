import {
  createStyleSheet,
  Icon,
  Text,
  useStyleSheet,
  useThemeContext,
  View
} from '@gopeerproject/ui-kit'
import { useAudioInputList } from '@gopeerproject/web-kit'
import { Dropdown } from '@/components'

export const AudioInputList = () => {
  const styles = useStyleSheet(getStyles)
  const { color } = useThemeContext()
  const { devices, onDeviceSelect, activeInput } = useAudioInputList()

  return (
    <Dropdown
      data={
        devices.length
          ? devices.map((device) => {
              return {
                text: device.label,
                onPress: () => onDeviceSelect(device.id),
                ...(activeInput.id === device.id
                  ? {
                      rightIcon: {
                        name: 'checkmark',
                        color: color.primary.solid
                      }
                    }
                  : {})
              }
            })
          : [{ text: 'No microphone detected' }]
      }
    >
      <View style={styles.container}>
        <Icon name={activeInput.enabled ? 'mic' : 'mic-off'} size={21} filled />
        <Text type='body' size='lg' weight='regular' style={styles.text}>
          {activeInput.label || 'No microphone detected'}
        </Text>
        <Icon name='chevron-down' size={21} />
      </View>
    </Dropdown>
  )
}

const getStyles = createStyleSheet(({ color, size }) => ({
  container: {
    flexDirection: 'row',
    padding: size.F,
    gap: size.C,
    borderRadius: size.C,
    backgroundColor: color.bg.v1,
    borderColor: color.invert2.p4,
    borderWidth: 1,
    borderStyle: 'solid',
    alignItems: 'center'
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1
  }
}))
