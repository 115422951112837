import React, { useEffect } from 'react'
import { useLocalMediaToggle } from '@gopeerproject/web-kit'

import { IconButton } from '@gopeerproject/ui-kit'
import { MediaRestrictionStatus } from '@/data'
import { useMemberControlsQuery } from '../../../hooks'

export const ToggleAudioButton = () => {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalMediaToggle('audio')

  // useEffect(() => {
  //   ClassroomManager.updateParticipant({ audio: isAudioEnabled })
  // }, [isAudioEnabled])

  const { data } = useMemberControlsQuery()

  const { audio: audioRestrictionStatus } = data!

  useEffect(() => {
    if (
      audioRestrictionStatus === MediaRestrictionStatus.RESTRICTED &&
      isAudioEnabled
    ) {
      toggleAudioEnabled()
    }
  }, [isAudioEnabled, audioRestrictionStatus, toggleAudioEnabled])

  return (
    <IconButton
      type='tertiary'
      size='lg'
      accent='neutral'
      onPress={toggleAudioEnabled}
      value={isAudioEnabled ? 'mic' : 'mic-off'}
      active
      disabled={audioRestrictionStatus === MediaRestrictionStatus.RESTRICTED}
    />
  )
}
