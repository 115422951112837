import { IconButton, IconName } from '@gopeerproject/ui-kit'

export const ToolButton: React.FC<{
  icon: IconName
  active?: boolean
  onPress: () => void
}> = ({ icon, active, onPress }) => {
  return (
    <IconButton
      value={icon}
      size='md'
      accent='neutral'
      type={active ? 'secondary' : 'tertiary'}
      active={active}
      onPress={onPress}
    />
  )
}
