import React from 'react'
import { useMediaContext } from '@gopeerproject/web-kit'

import { redirectToCore } from '@/utils'

import { IconButton } from '@gopeerproject/ui-kit'
import { ClassroomEnv, useClassroomEnv } from '../../../contexts'
import { useNavigate } from 'react-router-dom'

export const EndCallButton = () => {
  const { env } = useClassroomEnv()
  const { room } = useMediaContext()
  const navigate = useNavigate()

  return (
    <IconButton
      type='tertiary'
      size='lg'
      accent={'negative' as 'neutral'}
      active
      onPress={() => {
        switch (env) {
          case ClassroomEnv.CLASSROOM:
            room?.disconnect()
            // ClassroomManager.destroy()
            navigate('completed')
            break
          case ClassroomEnv.PLAYGROUND:
            // ClassroomManager.destroy()
            redirectToCore()
        }
      }}
      value='call-end'
    />
  )
}
