import { useEffect, useCallback, useReducer, useRef, useMemo } from 'react'

import { Button, Text, View } from '@gopeerproject/ui-kit'
import { useSocketMessageListener } from '@gopeerproject/chuck'
import { WaitingRoomStatus } from '@/data'
import { useMemberControlsQuery } from '../../hooks'

export const JoinButton: React.FC<{
  next: () => void
  styles: any
  isConnecting: boolean
  isAcquiringLocalTracks: boolean
}> = ({ next, styles, isConnecting, isAcquiringLocalTracks }) => {
  const { data: memberControls } = useMemberControlsQuery()
  const {
    run: joinClassroom,
    cancel,
    running,
    secondsLeft
  } = useScheduledCallback(next, 10)

  const updateClassroomGroupHandler = useCallback(
    (payload: { status: WaitingRoomStatus }) => {
      if (
        memberControls!.status === WaitingRoomStatus.WAITING &&
        payload.status === WaitingRoomStatus.ADMITTED
      ) {
        joinClassroom()
      }
    },
    [memberControls, joinClassroom]
  )

  useDocumentClick(cancel, running)

  useSocketMessageListener(
    // @ts-expect-error @TODO add this to chuck
    'update:classroom_group',
    updateClassroomGroupHandler
  )

  return (
    <>
      <Button
        key={memberControls!.status}
        type='primary'
        centered
        size='lg'
        accent='default'
        onPress={next}
        text='Join the session'
        isLoading={memberControls!.status !== WaitingRoomStatus.ADMITTED}
        disabled={isConnecting || isAcquiringLocalTracks}
      />

      <View style={styles.controls}>
        {running ? (
          <Text
            type='body'
            size='md'
            weight='regular'
            style={styles.waitingRoomMessage}
          >
            Auto-joining the session in {secondsLeft}{' '}
            {secondsLeft > 1 ? 'seconds' : 'second'}. Press anywhere on screen
            to cancel
          </Text>
        ) : null}
      </View>
    </>
  )
}

const useScheduledCallback = (cb: () => void, seconds: number) => {
  type State = { secondsLeft: number; running: boolean }
  type Action = { type: 'RUN' } | { type: 'CANCEL' } | { type: 'TICK' }

  const [state, dispatch] = useReducer(
    (state: State, action: Action): State => {
      switch (action.type) {
        case 'RUN':
          return { ...state, running: true }
        case 'CANCEL':
          return { secondsLeft: seconds, running: false }
        case 'TICK':
          return { ...state, secondsLeft: state.secondsLeft - 1 }
        default:
          throw new Error('INVALID ACTION')
      }
    },
    null,
    () => ({ secondsLeft: seconds, running: false })
  )

  const intervalRef = useRef<NodeJS.Timeout | null>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const cancel = useCallback(() => {
    dispatch({ type: 'CANCEL' })
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  const run = useCallback(() => {
    if (state.running) return
    dispatch({ type: 'RUN' })
    timeoutRef.current = setTimeout(() => {
      cb()
      cancel()
    }, seconds * 1000)

    intervalRef.current = setInterval(() => {
      dispatch({ type: 'TICK' })
    }, 1000)
  }, [cb, seconds, state.running, cancel])

  useEffect(() => {
    return () => {
      cancel()
    }
  }, [cancel])

  return useMemo(
    () => ({
      ...state,
      cancel,
      run
    }),
    [state, cancel, run]
  )
}

const useDocumentClick = (
  cb: () => void,
  predicate: (() => boolean) | boolean = true
) => {
  useEffect(() => {
    const handleClickOutside = () => {
      const check = typeof predicate === 'function' ? predicate() : predicate
      if (check) {
        cb()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [cb, predicate])
}
