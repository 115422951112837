import { Participant, Track } from 'twilio-video'
import { usePublications } from '@gopeerproject/web-kit'

import { Publication } from './Publication'

export const ParticipantTracks: React.FC<{
  participant: Participant
  videoOnly?: boolean
  enableScreenShare?: boolean
  videoPriority?: Track.Priority
  isLocalParticipant?: boolean
}> = ({
  participant,
  videoOnly,
  enableScreenShare,
  videoPriority,
  isLocalParticipant
}) => {
  const publications = usePublications(participant)
  let filteredPublications

  if (
    enableScreenShare &&
    publications.some((p) => p.trackName.includes('screen'))
  ) {
    filteredPublications = publications.filter(
      (p) => !p.trackName.includes('camera')
    )
  } else {
    filteredPublications = publications.filter(
      (p) => !p.trackName.includes('screen')
    )
  }

  return (
    <>
      {filteredPublications.map((publication) => (
        <Publication
          key={publication.trackSid}
          publication={publication}
          isLocalParticipant={isLocalParticipant}
          videoOnly={videoOnly}
          videoPriority={videoPriority}
        />
      ))}
    </>
  )
}
