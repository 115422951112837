import {
  useScreenShareParticipant,
  useMediaContext
} from '@gopeerproject/web-kit'
import { IconButton } from '@gopeerproject/ui-kit'
import { useClassroomDispatch } from '../../../contexts'

export const ToggleScreenShareButton = () => {
  const screenShareParticipant = useScreenShareParticipant()
  const { toggleScreenShare, room } = useMediaContext()
  const localParticipant = room?.localParticipant
  const classroomDispatch = useClassroomDispatch()
  const isScreenSharesupported =
    navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia

  const isScreenShareEnabled =
    screenShareParticipant && screenShareParticipant === localParticipant

  // TODO
  // - show modal when user declines screen sharing permission on Safari only
  // as chrome does not use permission based structure for getMediaDisplay
  // we are good on chrome

  // useEffect(() => {
  //   ClassroomManager.updateParticipant({
  //     screen: Boolean(isScreenShareEnabled)
  //   })
  // }, [isScreenShareEnabled])

  return (
    <IconButton
      type='secondary'
      size='lg'
      accent={isScreenShareEnabled ? 'negative' : 'default'}
      onPress={() =>
        !isScreenSharesupported || !room
          ? classroomDispatch({ type: 'open_screen_share_modal' })
          : isScreenShareEnabled
          ? toggleScreenShare()
          : screenShareParticipant
          ? classroomDispatch({ type: 'open_screen_share_modal' })
          : toggleScreenShare()
      }
      value={isScreenShareEnabled ? 'screen-share-stop' : 'screen-share-start'}
      active
    />
  )
}
