import { View, useStyleSheet, createStyleSheet } from '@gopeerproject/ui-kit'
import React from 'react'

export const Frame: React.FC<{
  children: React.ReactNode
  leftToolbar: React.ReactNode
  rightToolbar?: React.ReactNode
}> = ({ children, leftToolbar, rightToolbar }) => {
  const styles = useStyleSheet(getStyles)
  return (
    <View style={styles.root}>
      <View style={[styles.toolbarWrapper, styles.leftToolbar]}>
        {leftToolbar}
      </View>
      {rightToolbar ? (
        <View style={[styles.toolbarWrapper, styles.rightToolbar]}>
          {rightToolbar}
        </View>
      ) : null}
      <View style={styles.children}>{children}</View>
      <View style={styles.background}></View>
    </View>
  )
}

const getStyles = createStyleSheet(({ color, size, colorScheme }) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
    backgroundColor: color.bg.v1,
    borderRadius: size.G,
    borderWidth: 1,
    borderColor: color.invert2.p4
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    backgroundImage: `url(/media/sketchpad-background-${colorScheme}.png)`,
    backgroundRepeat: 'repeat',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    zIndex: 9
  },
  children: {
    zIndex: 10,
    height: '100%'
  },
  toolbarWrapper: {
    position: 'absolute',
    top: size.K,
    zIndex: 11
  },
  leftToolbar: {
    left: size.K,
    gap: size.C
  },
  rightToolbar: {
    right: size.K
  }
}))
