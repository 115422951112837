import {
  createStyleSheet,
  Text,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'

export const GlobalMessage: React.FC<{
  emoji: string
  title: string
  description: string
}> = ({ emoji, title, description }) => {
  const styles = useStyleSheet(getStyles)
  return (
    <View style={styles.container}>
      <Text type='body' weight='semibold' size='sm' style={styles.emoji}>
        {emoji}
      </Text>
      <Text type='heading' size='h4'>
        {title}
      </Text>
      <Text type='body' size='md' weight='regular' style={styles.description}>
        {description}
      </Text>
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: { textAlign: 'center', width: 380 },
  emoji: { fontSize: 55, lineHeight: 55, marginBottom: size.K },
  description: { color: color.invert2.p56, marginTop: size.C }
}))
