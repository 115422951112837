import {
  createStyleSheet,
  Focusable,
  Icon,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'

export const HeaderPlaceholder = () => {
  const styles = useStyleSheet(getStyles)
  return (
    <View style={styles.header}>
      <Focusable>
        <View style={[styles.homeBtn, styles.active]}>
          <Icon name='home' color={(color) => color.invert2.p56} size={19} />
        </View>
      </Focusable>
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  header: {
    height: size.M,
    backgroundColor: color.bg.v0,
    paddingTop: size.B,
    paddingHorizontal: size.K,
    flexDirection: 'row'
  },
  homeBtn: {
    backgroundColor: color.bg.v1,
    width: size.M - size.B,
    height: size.M - size.B,
    borderTopLeftRadius: size.C,
    borderTopRightRadius: size.C,
    alignItems: 'center',
    justifyContent: 'center'
  },
  active: {
    borderBottomColor: color.bg.v0,
    borderBottomWidth: 1
  }
}))
