import { Room, TwilioError } from 'twilio-video'
import { useCallback, useEffect } from 'react'
import { useMediaContext, useOnRoomReconnecting } from '@gopeerproject/web-kit'
import { useToastDispatch } from '../../contexts'
import { useNavigate } from 'react-router-dom'

const ERROR_CODES = {
  UNSTABLE_SIGNALING_CONN: 53001,
  UNSTABLE_MEDIA_CONN: 53405,
  ENDED_VIA_API: 53118
} as const

const errorMessages: Record<number, { title: string; subtitle: string }> = {
  [ERROR_CODES.UNSTABLE_SIGNALING_CONN]: {
    title: 'Your network connection is unstable',
    subtitle: 'Reconnecting your signaling connection'
  },
  [ERROR_CODES.UNSTABLE_MEDIA_CONN]: {
    title: 'Your network connection is unstable',
    subtitle: 'Trying to reconnect'
  },
  /**
   * @ref https://www.twilio.com/docs/api/errors/53118
   */
  [ERROR_CODES.ENDED_VIA_API]: {
    title: 'Disconnected because you reached the end time',
    subtitle: ''
  }
}

export const useConnectionToasts = () => {
  const { addToast } = useToastDispatch()
  const { room } = useMediaContext()
  const navigate = useNavigate()

  const onDisconnected = useCallback(
    (reason: TwilioError) => {
      if (reason.code !== ERROR_CODES.ENDED_VIA_API) {
        addToast(
          {
            accent: 'negative',
            icon: 'cellular-unavailable',
            title: 'Your network connection is unstable',
            subtitle: "You've been disconnected"
          },
          { selfDismiss: false }
        )
        return
      }

      navigate('completed?expired=true', { replace: true })
    },
    [addToast, navigate]
  )

  const onReconnecting = useCallback(
    (error: TwilioError) => {
      const errors = errorMessages[error.code] ?? {
        title: 'Your connection is unstable',
        subtitle: 'Trying to reconnect'
      }
      addToast({
        ...errors,
        accent: 'negative',
        icon: 'cellular-unavailable'
      })
    },
    [addToast]
  )

  const onReconnected = useCallback(() => {
    addToast({
      title: 'Connection has been restored',
      accent: 'default',
      icon: 'cellular-data'
    })
  }, [addToast])

  useOnRoomDisconnected(room, onDisconnected)
  useOnRoomReconnected(room, onReconnected)
  useOnRoomReconnecting(room, onReconnecting)

  return null
}

const useOnRoomDisconnected = (
  room: Room | null,
  onError: (error: TwilioError) => void
) => {
  useEffect(() => {
    if (!room) return

    const onDisconnected = (_: Room, error?: TwilioError) => {
      if (error) onError(error)
    }

    room.on('disconnected', onDisconnected)
    return () => {
      room.off('disconnected', onDisconnected)
    }
  }, [room, onError])
}

const useOnRoomReconnected = (room: Room | null, onReconnected: () => void) => {
  useEffect(() => {
    if (!room) return
    room.on('reconnected', onReconnected)
    return () => {
      room.off('reconnected', onReconnected)
    }
  }, [room, onReconnected])
}
