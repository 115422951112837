import {
  createStyleSheet,
  Divider,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'
import { Header } from '../Header'
import { useClassroomState } from '../../contexts'
import { Footer } from '../Footer'
import { Settings } from '../Settings'
import { SidebarToggles } from '../SidebarToggles'
import { Sidebar } from '../Sidebar'
import { ParticipantGrid } from '../Media/Participants'
import { ToastsContainer } from '../Toasts'
import { useConnectionToasts } from '../Media'
import { SketchPad } from '../Sketchpad'

export const Main = () => {
  const styles = useStyleSheet(getStyles)
  const { isSettingsModalOpen, activeTabId } = useClassroomState()
  useConnectionToasts()

  const isFileOpen = activeTabId !== null
  return (
    <>
      {/* <ConnectionBanner /> */}
      {/* {notifications.expiring && (
        <ExpirationBanner />
      )} */}

      <Header />
      <View style={styles.container} testID='container'>
        <View style={styles.leftSide} testID='leftSide'>
          <View
            style={[styles.main, isFileOpen && styles.sketching]}
            testID='main'
          >
            {isFileOpen ? (
              <>
                <SketchPad />
                <ParticipantGrid isFileOpen />
              </>
            ) : (
              <View style={styles.media} testID='media'>
                <ParticipantGrid />
              </View>
            )}
            <SidebarToggles isFileOpen={isFileOpen} />
          </View>
          <Footer withControls />

          <ToastsContainer />
        </View>

        <Divider type='vertical' style={styles.divider} />
        <Sidebar />

        {isSettingsModalOpen && <Settings />}

        {/* {isFullscreenModalOpen && (
        <FullscreenModal
          close={() => dispatch({ type: 'close_fullscreen_modal' })}
          maximize={() => {
            dispatch({ type: 'close_fullscreen_modal' })
            maximize()
          }}
        />
      )}

      {isScreenShareModalOpen && (
        <ScreenShareModal
          close={() => dispatch({ type: 'close_screen_share_modal' })}
        />
      )} */}
      </View>
    </>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: {
    flexDirection: 'row',
    height: '100%',

    flexBasis: '0%',
    flexGrow: 1,
    flexShrink: 1
  },
  leftSide: {
    position: 'relative',
    height: '100%',

    flexBasis: '0%',
    flexGrow: 1,
    flexShrink: 1
  },
  main: {
    flexDirection: 'row',
    padding: size.K,
    paddingBottom: 0,
    flexBasis: '0%',
    flexGrow: 1,
    flexShrink: 1
  },
  sketching: {
    padding: 0
  },
  media: {
    position: 'relative',

    flexBasis: '0%',
    flexGrow: 1,
    flexShrink: 1
  },
  divider: {
    backgroundColor: color.bg.v0
  },
  conversation: {}
}))
