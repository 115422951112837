import { formatUrl, Sentry, storage } from '@/utils'
import {
  AwarenessContextProvider,
  ChuckClientProvider,
  initChuckClient,
  ProfileContextProvider,
  Storage,
  UserContextProvider
} from '@gopeerproject/chuck'
import { SocketContextProvider } from './SocketContext'

const chuckClient = initChuckClient({
  formatUrl: formatUrl,
  captureException: (err) => Sentry.track(err as Error),
  Storage: storage as unknown as Storage,
  storeTokenLocally: true
})

export const ChuckContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return (
    <ChuckClientProvider client={chuckClient}>
      <UserContextProvider>
        <ProfileContextProvider>
          <AwarenessContextProvider>
            <SocketContextProvider>{children}</SocketContextProvider>
          </AwarenessContextProvider>
        </ProfileContextProvider>
      </UserContextProvider>
    </ChuckClientProvider>
  )
}
