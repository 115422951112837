import React from 'react'

import { normalizeShortcut } from '../helpers'

const ShortcutsContext = React.createContext<{
  registerShortcut: (key: string, handler: (e: KeyboardEvent) => void) => void
  unregisterShortcut: (key: string) => void
}>({
  registerShortcut: () => {},
  unregisterShortcut: () => {}
})

export const useShortcutsContext = () => React.useContext(ShortcutsContext)

export const ShortcutsProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [shortcuts, setShortcuts] = React.useState<
    Record<string, (e: KeyboardEvent) => void>
  >({})
  const keyMapping = React.useRef<Record<string, string>>({})

  const registerShortcut = React.useCallback(
    (key: string, handler: (_: KeyboardEvent) => void) => {
      setShortcuts((prev) => ({
        ...prev,
        [normalizeShortcut(key)]: handler
      }))
    },
    []
  )

  const unregisterShortcut = React.useCallback((key: string) => {
    setShortcuts((prev) => {
      const nextState = {
        ...prev
      }

      delete nextState[key]
      return nextState
    })
  }, [])

  React.useEffect(() => {
    if (!navigator.keyboard) return

    navigator.keyboard.getLayoutMap().then((layout) => {
      keyMapping.current = Array.from(layout).reduce(
        (result, [keyCode, keyValue]) => ({
          ...result,
          [keyCode]: keyValue
        }),
        {}
      )
    })
  }, [])

  React.useEffect(() => {
    const handleKeyDown = async (event: KeyboardEvent) => {
      const key = keyMapping.current?.[event.code] ?? event.key
      let shortcut = `+${key}`

      if (event.metaKey) shortcut = `,Cmd${shortcut.replace('+Meta', '')}`
      if (event.altKey) shortcut = `,Alt${shortcut.replace('+Alt', '')}`
      if (event.shiftKey) shortcut = `,Shift${shortcut.replace('+Shift', '')}`
      if (event.ctrlKey) shortcut = `,Ctrl${shortcut.replace('+Control', '')}`

      shortcut = shortcut.startsWith('+') ? shortcut.slice(1) : shortcut
      shortcut = shortcut.startsWith(',') ? shortcut.slice(1) : shortcut
      shortcut = shortcut.replace(',', '+')

      const normalized = normalizeShortcut(shortcut)
      const handler = shortcuts[normalized]

      if (handler) {
        handler(event)
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [shortcuts])

  const value = {
    registerShortcut,
    unregisterShortcut
  }

  return (
    <ShortcutsContext.Provider value={value}>
      {children}
    </ShortcutsContext.Provider>
  )
}
