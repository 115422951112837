import {
  LocalTrackPublication,
  RemoteTrackPublication,
  Track
} from 'twilio-video'

import { useTrack, AudioTrack, VideoTrack } from '@gopeerproject/web-kit'

export const Publication: React.FC<{
  publication: LocalTrackPublication | RemoteTrackPublication
  isLocalParticipant?: boolean
  videoOnly?: boolean
  videoPriority?: Track.Priority
}> = ({ publication, isLocalParticipant, videoOnly, videoPriority }) => {
  const track = useTrack(publication)

  if (!track) return null

  switch (track.kind) {
    case 'video':
      return (
        <VideoTrack
          track={track}
          priority={videoPriority}
          isLocal={track.name.includes('camera') && isLocalParticipant}
        />
      )
    case 'audio':
      return videoOnly ? null : <AudioTrack track={track} />
    default:
      return null
  }
}
