import { createStyleSheet, useStyleSheet, View } from '@gopeerproject/ui-kit'
import { Participant } from './Participant'
import {
  useSelectedParticipant,
  useParticipants,
  useMediaContext,
  useScreenShareParticipant
} from '@gopeerproject/web-kit'

export const ParticipantGrid: React.FC<{ isFileOpen?: boolean }> = ({
  isFileOpen = false
}) => {
  const styles = useStyleSheet(getStyles)
  const { room } = useMediaContext()
  const localParticipant = room?.localParticipant
  const participants = useParticipants()
  const [selectedParticipant] = useSelectedParticipant()
  const screenShareParticipant = useScreenShareParticipant()

  const isRemoteParticipantScreenSharing =
    screenShareParticipant && screenShareParticipant !== localParticipant

  if (isRemoteParticipantScreenSharing)
    return (
      <>
        <div className='media-grid grid-container'>
          <div className='grid-item'>
            <Participant
              participant={screenShareParticipant}
              enableScreenShare={true}
              videoOnly
            />
          </div>
        </div>
        <View style={styles.floatingList}>
          {participants.map((participant) => {
            return (
              <Participant
                isMinimized
                key={participant.sid}
                participant={participant}
                isSelected={participant === selectedParticipant}
                // onClick={() => setSelectedParticipant(participant)}
              />
            )
          })}
        </View>
      </>
    )

  if (isFileOpen)
    return (
      <View style={styles.floatingList}>
        {participants.map((participant) => {
          return (
            <Participant
              isMinimized
              key={participant.sid}
              participant={participant}
              isSelected={participant === selectedParticipant}
              // onClick={() => setSelectedParticipant(participant)}
            />
          )
        })}
      </View>
    )

  if (!localParticipant && participants.length === 0) return null

  return (
    <div className='media-grid grid-container'>
      <div className='grid-item'>
        <Participant
          participant={localParticipant!}
          isLocalParticipant={true}
        />
      </div>

      {participants.map((participant) => {
        return (
          <div className='grid-item' key={participant.sid}>
            <Participant
              participant={participant}
              isSelected={participant === selectedParticipant}
              // onClick={() => setSelectedParticipant(participant)}
            />
          </div>
        )
      })}
    </div>
  )
}

const getStyles = createStyleSheet(({ size }) => ({
  floatingList: {
    position: 'absolute',

    flexDirection: 'column-reverse',

    bottom: size.E,
    right: size.E,
    gap: size.C
  }
}))
