/** @ref https://codesandbox.io/s/distracted-swirles-jo1pvu?file=/src/App.tsx */
import {
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
  Placement
} from '@floating-ui/react'
import { createStyleSheet, useStyleSheet, View } from '@gopeerproject/ui-kit'

import { usePopover, PopoverContext, usePopoverContext } from './context'
import React, { forwardRef } from 'react'

export { usePopoverContext } from './context'

export const Popover: React.FC<{
  children: React.ReactNode
  modal?: boolean
  initialOpen?: boolean
  placement: Placement
  open: boolean
  onOpenChange: (isOpen: boolean) => void
}> = ({ children, modal = false, ...restOptions }) => {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions })
  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  )
}

export const PopoverTrigger: React.FC<{
  children: React.ReactNode
}> = forwardRef(function PopoverTrigger({ children, ...props }, propRef) {
  const context = usePopoverContext()

  // @ts-expect-error low level stuff, copied from library examples
  const childrenRef = children.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  return (
    <View ref={ref} {...context.getReferenceProps(props)}>
      {children}
    </View>
  )
})

export const PopoverContent: React.FC<{ children: React.ReactNode }> =
  forwardRef(function PopoverContent({ ...props }, propRef) {
    const styles = useStyleSheet(getStyles)
    const { context: floatingContext, ...context } = usePopoverContext()
    const ref = useMergeRefs([context.refs.setFloating, propRef])

    if (!floatingContext.open) return null

    return (
      <FloatingPortal>
        <FloatingFocusManager context={floatingContext} modal={context.modal}>
          <View
            ref={ref}
            // @ts-expect-error RNW
            style={[context.floatingStyles, styles.content]}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </View>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  })

const getStyles = createStyleSheet(({ size, color }) => ({
  content: {
    paddingHorizontal: size.E,
    paddingVertical: size.E,
    backgroundColor: color.bg.v3,
    borderWidth: 1,
    borderRadius: size.G,
    borderColor: color.invert2.p4,
    minWidth: 260,
    zIndex: 2
  }
}))
