import { fabric } from 'fabric'
import { SK } from '../constants'
import { Formula, Graph } from '../FormulaTools'

export function getObjectType(object: fabric.Object) {
  switch (true) {
    case object instanceof Graph:
    case object instanceof Formula:
      return SK.TOOLS.MATH
    case object instanceof fabric.Textbox:
      return SK.TOOLS.TEXT
    case object instanceof fabric.Image:
      return SK.TOOLS.IMAGE
    case object instanceof fabric.Line:
      return SK.TOOLS.LINE
    case object instanceof fabric.Rect:
      return SK.TOOLS.RECT
    case object instanceof fabric.Ellipse:
      return SK.TOOLS.CIRCLE
    case object instanceof fabric.Triangle:
      return SK.TOOLS.TRIANGLE
    case object instanceof fabric.Path:
      return SK.TOOLS.PENCIL
  }
}

export const getObjectEditableProps = (
  type: keyof typeof SK.TOOLS | undefined
) => {
  switch (type) {
    case SK.TOOLS.PENCIL:
    case SK.TOOLS.LINE:
      return {
        strokeWidth: true,
        strokeStyle: true,
        strokeColor: true,
        fill: false
      }

    case SK.TOOLS.RECT:
    case SK.TOOLS.CIRCLE:
    case SK.TOOLS.TRIANGLE:
      return {
        strokeWidth: true,
        strokeStyle: true,
        strokeColor: true,
        fill: true
      }

    case SK.TOOLS.TEXT:
      return {
        strokeWidth: false,
        strokeStyle: false,
        strokeColor: true,
        fill: true
      }

    case SK.TOOLS.IMAGE:
      return {
        strokeWidth: false,
        strokeStyle: false,
        strokeColor: false,
        fill: false
      }
    case SK.TOOLS.MATH:
      return {
        strokeWidth: false,
        strokeStyle: false,
        strokeColor: true,
        fill: false,
        settingsModal: true
      }
  }
}
