import React from 'react'
import { useDrop } from 'react-dnd'

export const Droppable = React.forwardRef<
  HTMLDivElement,
  {
    item: { type: 'string' }
    style: StylePropertyMap
    enabled?: boolean
    className?: string
    events?: any
    children: (isOver: boolean) => React.ReactNode
    onDrop: (...args: any) => void
  }
>(
  (
    {
      item,
      enabled = true,
      className = '',
      style = {},
      events = {},
      children,
      onDrop
    },
    ref
  ) => {
    const [{ isOver }, drop] = useDrop({
      accept: item.type,
      drop: onDrop,
      collect: (monitor) => ({
        isOver: !!monitor.isOver({ shallow: true })
      })
    })

    return (
      <div
        ref={(elem) => {
          // @ts-ignore TODO
          if (ref) ref(elem)
          if (enabled) drop(elem)
        }}
        {...{ className, style, ...events }}
      >
        {typeof children === 'function' ? children(isOver) : children}
      </div>
    )
  }
)
Droppable.displayName = 'Droppable'
