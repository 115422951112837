import { CacheKeys } from '@/constants'
import { TClassroom } from '@/types'
import { apiClient, queryClient } from '@/utils'

export const queryClassroom = (id: string) => {
  return queryClient.fetchQuery({
    queryKey: [CacheKeys.classrooms, id],
    queryFn: () =>
      apiClient
        .get({
          url: `/classrooms/${id}`
        })
        .then((res) => res.data as TClassroom),
    retry: false,
    staleTime: Infinity
  })
}

export const openClassroom = async (id: string) => {
  await apiClient.post({ url: `/classrooms/${id}/open`, data: {} })
}
