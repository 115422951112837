import {
  createStyleSheet,
  Focusable,
  Icon,
  IconButton,
  IconName,
  Text,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'
import {
  Feature,
  useClassroom,
  useClassroomManagerRef,
  useClassroomPermissions
} from '../../contexts'
import type { Tab, TabType } from '@/screens/classroom/utils/ClassroomManager'
import { useCallback, useState } from 'react'
import { useClassroomQuery, useFileCreate } from '../../hooks'
import { useAuth } from '@/contexts'
import capitalize from 'lodash/capitalize'

export const Header = () => {
  const styles = useStyleSheet(getStyles)
  const [{ tabs, activeTabId }] = useClassroom()
  const { data: classroom } = useClassroomQuery()
  const { participants, id, mode } = classroom!
  const { profile } = useAuth()
  const classroomManagerRef = useClassroomManagerRef()
  const { mutateAsync: createNewFile, status } = useFileCreate()
  const { checkPermission } = useClassroomPermissions()

  const createFilePermission = checkPermission(Feature.TOOL_NEW_FILE)
  const switchFilePermission = checkPermission(Feature.TOOL_SWITCH_FILE)

  const isCreatingFile = status === 'loading'

  const generateFileName = useCallback(
    (_type: 'sketch') => {
      const type = capitalize(_type)
      let i = 1
      const tabNames = classroomManagerRef
        .current!.getTabs()
        .tabs.map((tab) => tab.name)
      while (tabNames.includes(`${type} ${i}`)) i++
      return `${type} ${i}`
    },
    [classroomManagerRef]
  )

  const createFile = useCallback(() => {
    return createNewFile({
      name: generateFileName('sketch'),
      type: 'sketch',
      participants: [profile!.id, ...Object.keys(participants || {})],
      ...(mode !== 'individual' ? { appointment: id } : {})
    }).then((file) => {
      classroomManagerRef.current!.openTab({
        id: file.id,
        type: file.type,
        name: file.name
      })
    })
  }, [
    classroomManagerRef,
    createNewFile,
    generateFileName,
    id,
    mode,
    participants,
    profile
  ])

  return (
    <View style={styles.header}>
      <TabIcon
        icon='home'
        onPress={() => {
          if (!switchFilePermission) return
          classroomManagerRef.current!.activateTab(null)
        }}
        isActive={activeTabId === null}
      />

      <View style={styles.tabs}>
        {tabs.map((tab) => (
          <Tab key={tab.id} tab={tab} activeTabId={activeTabId} />
        ))}
        {createFilePermission ? (
          <TabIcon
            icon='add'
            onPress={() => {
              createFile()
            }}
            isActive={false}
            isLoading={isCreatingFile}
          />
        ) : null}
      </View>
    </View>
  )
}

const Tab = ({
  tab,
  activeTabId
}: {
  tab: Tab
  activeTabId: string | null
}) => {
  const styles = useStyleSheet(getStyles)
  const classroomManagerRef = useClassroomManagerRef()
  const { checkPermission } = useClassroomPermissions()

  const switchFilePermission = checkPermission(Feature.TOOL_SWITCH_FILE)

  const [isHovered, setIsHovered] = useState(false)

  const isActive = activeTabId === tab.id

  return (
    <Focusable
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      key={tab.id}
      style={[styles.tab, isActive || isHovered ? styles.tabActive : null]}
      hoverStyle={styles.tabActive}
      onPress={() => {
        if (!switchFilePermission) return
        if (isActive) return
        classroomManagerRef.current!.activateTab({
          id: tab.id
        })
      }}
    >
      <Icon
        size={19}
        color={(color) => color.invert2.p56}
        name={getTabIcon(tab.type)}
      />
      <View style={styles.tabTextWrapper}>
        <Text size='sm' type='body' weight='regular' style={styles.tabText}>
          {tab.name}
        </Text>
      </View>

      {(isHovered || isActive) && switchFilePermission ? (
        <IconButton
          value='dismiss'
          onPress={() => {
            classroomManagerRef.current!.closeTab({
              id: tab.id
            })
          }}
          size='xxs'
          accent='neutral'
          type='tertiary'
          style={styles.tabClose}
        />
      ) : null}
    </Focusable>
  )
}

const TabIcon = ({
  isActive,
  icon,
  onPress,
  isLoading,
  disabled
}: {
  isActive: boolean
  icon: IconName
  onPress: () => void
  isLoading?: boolean
  disabled?: boolean
}) => {
  const styles = useStyleSheet(getStyles)
  return (
    <Focusable
      style={[styles.headerIconBtn, isActive ? styles.tabActive : null]}
      hoverStyle={styles.tabActive}
      onPress={onPress}
      disabled={disabled || isLoading}
    >
      <Icon name={icon} color={(color) => color.invert2.p56} size={19} />
    </Focusable>
  )
}

const getTabIcon = (type: TabType): IconName => {
  switch (type) {
    case 'sketch':
      return 'draw-shape'
    default:
      return 'alert'
  }
}

const getStyles = createStyleSheet(({ size, color }) => ({
  header: {
    height: size.M,
    backgroundColor: color.bg.v0,
    paddingTop: size.B,
    paddingHorizontal: size.K,
    flexDirection: 'row',
    maxWidth: '100%'
  },
  homeBtn: {
    backgroundColor: color.bg.v1
  },
  headerIconBtn: {
    width: size.M - size.B,
    height: size.M - size.B,
    borderTopLeftRadius: size.C,
    borderTopRightRadius: size.C,
    alignItems: 'center',
    justifyContent: 'center'
  },
  tabs: {
    flexDirection: 'row',
    flexGrow: 1,
    marginHorizontal: 1,
    gap: 1,
    maxWidth: `calc(100% - ${size.M - size.B}px)` as '100%'
  },
  tab: {
    height: size.M - size.B,
    alignItems: 'center',
    gap: 4,
    flexDirection: 'row',
    flexGrow: 0,
    flexShrink: 1,
    flexBasis: 181,

    borderTopLeftRadius: size.C,
    borderTopRightRadius: size.C,

    padding: size.D,
    borderBottomWidth: 1
  },
  tabActive: {
    backgroundColor: color.bg.v1,
    borderBottomColor: color.bg.v0
  },
  tabTextWrapper: {
    flexGrow: 1,
    flexShrink: 1
  },
  tabText: {
    color: color.invert2.p56,
    mixWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tabClose: {
    transform: 'scale(0.7)'
  },
  tabCloseHover: {
    backgroundColor: color.invert2.p10
  }
}))
