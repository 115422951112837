import { useAuth } from '@/contexts'

export const DemoClassroom = () => {
  const { user, profile } = useAuth()
  return (
    <div>
      <h1>Demo classroom</h1>
      <pre>{JSON.stringify({ user, profile }, null, 2)}</pre>
    </div>
  )
}
