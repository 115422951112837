import React, { useState } from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  Placement
} from '@floating-ui/react'
import {
  createStyleSheet,
  Text,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'

interface TooltipOptions {
  initialOpen?: boolean
  placement?: Placement
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function useTooltip({
  initialOpen = false,
  placement = 'right'
}: TooltipOptions) {
  const [open, setOpen] = useState(initialOpen)

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: 8
      }),
      flip({
        crossAxis: placement.includes('-'),
        fallbackAxisSideDirection: 'start',
        padding: 5
      }),
      shift({ padding: 5 })
    ]
  })

  const context = data.context

  const hover = useHover(context, {
    move: false,
    delay: {
      open: 1500,
      close: 100
    }
  })
  const focus = useFocus(context, {})

  const dismiss = useDismiss(context, {
    referencePress: true
  })
  const role = useRole(context, { role: 'tooltip' })

  const interactions = useInteractions([hover, focus, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data
    }),
    [open, setOpen, interactions, data]
  )
}

type ContextType = ReturnType<typeof useTooltip> | null
const TooltipContext = React.createContext<ContextType>(null)

export const useTooltipContext = () => {
  const context = React.useContext(TooltipContext)

  if (context == null) {
    throw new Error('Tooltip components must be wrapped in <Tooltip />')
  }

  return context
}

export function TooltipManager({
  children,
  ...options
}: {
  children: React.ReactNode
} & TooltipOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const tooltip = useTooltip(options)
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  )
}

export const TooltipTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & { asChild?: boolean }
>(function TooltipTrigger({ children, asChild = false, ...props }, propRef) {
  const context = useTooltipContext()
  const childrenRef = (children as any).ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && React.isValidElement(children)) {
    return React.cloneElement(
      children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
        'data-state': context.open ? 'open' : 'closed'
      })
    )
  }

  return (
    <View
      ref={ref}
      // The user can style the trigger based on the state
      data-state={context.open ? 'open' : 'closed'}
      {...context.getReferenceProps(props)}
    >
      {children}
    </View>
  )
})

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function TooltipContent({ label, ...props }, propRef) {
  const styles = useStyleSheet(getStyles)
  const context = useTooltipContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  if (!context.open) return null

  return (
    <FloatingPortal>
      <View
        ref={ref as any}
        style={[context.floatingStyles as any, styles.content]}
        {...context.getFloatingProps(props)}
      >
        <Text size='md' type='headline'>
          {label}
        </Text>
      </View>
    </FloatingPortal>
  )
})

export const Tooltip: React.FC<{
  label: string
  placement?: Placement
  asChild?: boolean
  children: React.ReactNode
}> = ({ label, placement, asChild, children }) => {
  return (
    <TooltipManager placement={placement}>
      <TooltipTrigger asChild={asChild}>{children}</TooltipTrigger>
      <TooltipContent label={label} />
    </TooltipManager>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  content: {
    paddingHorizontal: size.C,
    paddingVertical: size.E,
    backgroundColor: color.bg.v3,
    borderWidth: 1,
    borderRadius: size.G,
    borderColor: color.invert2.p4
  }
}))
