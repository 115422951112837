import React from 'react'

import { IconButton } from '@gopeerproject/ui-kit'
import { useClassroomDispatch } from '../../../contexts'

export const SettingsButton = () => {
  const dispatch = useClassroomDispatch()

  return (
    <IconButton
      type='tertiary'
      size='lg'
      accent='neutral'
      active
      onPress={() => dispatch({ type: 'open_settings_modal' })}
      value='settings'
    />
  )
}
