import React, { useCallback } from 'react'
import {
  Appointment,
  SocketContextProvider as ChuckSocketContextProvider,
  useSocketMessageListener
} from '@gopeerproject/chuck'

import { useQueryClient } from 'react-query'
import pick from 'lodash/pick'
import { CacheKeys } from '@/constants'
import { useMemberControlsRealtimeActionsHandler } from '../screens/classroom/components/MemberControls/hooks'

const useRealtimeUpdates = () => {
  const queryClient = useQueryClient()

  const onAppointmentUpdate = useCallback(
    (payload: unknown) => {
      const appointment = payload as Appointment

      queryClient.setQueryData(
        [CacheKeys.classrooms, appointment.id],
        (prevState?: Appointment) => {
          return {
            ...prevState,
            ...pick(appointment, [
              'overtime',
              'duration',
              'status',
              'twilioRoom'
            ])
          } as Appointment
        }
      )
    },
    [queryClient]
  )

  useSocketMessageListener('update:appointment', onAppointmentUpdate)

  useMemberControlsRealtimeActionsHandler()
}

const RealtimeUpdatesProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  useRealtimeUpdates()
  return children
}

export const SocketContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  return (
    <ChuckSocketContextProvider url={import.meta.env.VITE_SOCKET_URL}>
      <RealtimeUpdatesProvider>{children}</RealtimeUpdatesProvider>
    </ChuckSocketContextProvider>
  )
}
