import { pdfjs } from 'react-pdf'
import {
  SK
  // , Files
} from '../constants'

// TODO
// pdfjs.GlobalWorkerOptions.workerSrc = Files.PDF_WORKER // TODO figure out why this doesn't work out of the box

const pageToBlob = (page) =>
  new Promise((resolve) => {
    const viewport = page.getViewport({ scale: 1 })
    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width
    const renderContext = {
      canvasContext: context,
      viewport: viewport
    }
    return page.render(renderContext).promise.then(() =>
      canvas.toBlob(
        (blob) =>
          resolve({
            blob,
            height: canvas.height,
            width: canvas.width
          }),
        'image/png'
      )
    )
  })

const convertPDF = (file, handleChange) =>
  new Promise((resolve) => {
    const fileReader = new FileReader()
    fileReader.onload = (e) =>
      pdfjs
        .getDocument(new Uint8Array(e.target.result))
        .promise.then(
          (pdf) =>
            new Promise((resolvePages) =>
              handleChange({
                pageSelector: {
                  numPages: pdf.numPages,
                  callback: ([a, b]) => {
                    const pages = []
                    for (; a <= b; a++)
                      pages.push(pdf.getPage(a).then(pageToBlob))
                    return resolvePages(Promise.all(pages))
                  }
                }
              })
            )
        )
        .then((blobs) =>
          handleChange({ pageSelector: null }, () => {
            let lastPos = 10
            resolve(
              Promise.all(
                blobs.map(({ blob, height, width }, i) => {
                  let file = [
                    new File([blob], `pdf-${i}.png`, { type: 'image/png' }),
                    [lastPos, 10]
                  ]
                  lastPos += width * (SK.MEDIA_SCALE_HEIGHT / height) + 10
                  return file
                })
              )
            )
          })
        )
    fileReader.readAsArrayBuffer(file)
  })

export const convertSketchFile = (file, handleChange) =>
  (SK.FILE_TYPES.PDF.includes(file.type) && convertPDF(file, handleChange)) ||
  Promise.resolve([[file]])
