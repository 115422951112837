import Delta from 'quill-delta'

export type QuillMessage = {
  type: string
  value: string | unknown
  href?: string
  style?: {
    ordered?: boolean
    bullet?: boolean
  }
}

export const transform = (message: Delta) => {
  const result = group(
    message.ops
      .reduceRight<Delta['ops']>(
        // trim tail new line character
        (acc, v) =>
          !acc.length && v.insert === '\n' && !v.attributes ? acc : [v, ...acc],
        []
      )
      .map<QuillMessage>((v) => {
        if ((v.insert as { entity?: unknown } | undefined)?.entity)
          return {
            value: (v.insert as { entity: unknown }).entity,
            type: 'entity'
          }

        return {
          value: (v.insert as string)
            .replace(/\r\n/g, '\n')
            .replace(/\r/g, '\n'),
          ...(v.attributes
            ? {
                style: Object.keys(v.attributes).reduce((acc, a) => {
                  return ['link', 'list'].includes(a)
                    ? acc
                    : { ...acc, [a]: v.attributes?.[a] as boolean }
                }, {})
              }
            : {}),
          ...(v.attributes && Object.keys(v.attributes).includes('list')
            ? { type: 'list', style: { [v.attributes.list]: true } }
            : v.attributes && Object.keys(v.attributes).includes('link')
            ? { type: 'link', href: v.attributes.link }
            : { type: 'text' })
        }
      })
      .reduce<QuillMessage[]>((acc, v, i, arr) => {
        return v.type === 'text'
          ? [
              ...acc,
              ...(v.value as string)
                .split('\n')
                .reduceRight<string[]>(
                  // here we get rid of any new line characters in case this is the
                  // last chunk of the message, basically we trim
                  (acc, l) =>
                    i === arr.length - 1
                      ? !acc.length && !l
                        ? acc
                        : [l, ...acc]
                      : [l, ...acc],
                  []
                )
                .map((l, i, arr) => ({
                  // we peform join here
                  value: i !== arr.length - 1 ? `${l}\n` : l,
                  type: 'text',
                  ...(v.style ? { style: v.style } : {})
                })) // filter out all the empty objects, this would happen if new line characters where its own chunks
                .filter((l) => !!l.value)
            ]
          : [...acc, v]
      }, [])
  )
  return result
}

const group = (message: QuillMessage[]) => {
  return message.reduce<QuillMessage[]>((acc, v) => {
    const [fst, snd] = acc.slice(-2).reverse()

    return fst && v.type === 'list'
      ? snd &&
        snd.type === 'list' &&
        ((snd.style?.ordered && v.style?.ordered) ||
          (snd.style?.bullet && v.style?.bullet))
        ? [
            ...acc.slice(0, -2),
            {
              ...snd,
              value: [
                ...(snd.value as unknown[]),
                {
                  type: fst.type,
                  value: fst.value,
                  ...(fst.style ? { style: fst.style } : {})
                }
              ]
            }
          ]
        : [
            ...acc.slice(0, -1),
            {
              type: 'list',
              value: [
                {
                  type: fst.type,
                  value: fst.value,
                  ...(fst.style ? { style: fst.style } : {})
                }
              ],
              style: v.style
            }
          ]
      : [...acc, v]
  }, [])
}

export const toQuillOps = ({
  data
}: {
  data: (
    | { type: 'text'; value: string }
    | { type: 'link'; value: string; href: string }
  )[]
}) => {
  return data.map((op) => {
    switch (op.type) {
      case 'text':
        return {
          insert: op.value
        }
      case 'link':
        return {
          attributes: { color: '#2d304b', link: op.href },
          insert: op.value
        }
      default:
        op = op as any
        console.warn('NOT HANDLED', op.type)
        return { insert: op.value }
    }
  })
}
