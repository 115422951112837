import React, { useRef, useState } from 'react'
import merge from 'lodash/merge'
import { SK } from '../constants'

export type EditorContext = {
  tool: (typeof SK.TOOLS)[keyof typeof SK.TOOLS]
  line: {
    width: (typeof SK.LINE_WIDTHS)[number]['value']
    style: (typeof SK.LINE_STYLES)[number]['value']
  }
  color: {
    fill: string
    border: string
  }
}

const initialState: EditorContext = {
  tool: SK.TOOLS.PENCIL,
  color: {
    fill: '(0,0,0)',
    border: SK.COLOR_PALETTE[2]
  },
  line: {
    width: SK.LINE_WIDTHS[1].value,
    style: 'normal'
  }
}

type DeepPartial<T> = Partial<{
  [K in keyof T]: Partial<T[K]>
}>

export const EditorContext = React.createContext<{
  state: EditorContext
  setEditorState: (patch: DeepPartial<EditorContext>) => void
  canvas: React.MutableRefObject<fabric.Canvas | undefined>
}>({} as any)

export const EditorContextProvider: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  const [state, setState] = useState<EditorContext>(initialState)

  const canvas = useRef<fabric.Canvas>()

  const setEditorState = (patch: DeepPartial<EditorContext>) => {
    setState(merge({ ...state }, patch))
  }

  return (
    <EditorContext.Provider value={{ state, setEditorState, canvas }}>
      {props.children}
    </EditorContext.Provider>
  )
}
