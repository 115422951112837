import { useContext } from 'react'
import {
  useStyleSheet,
  createStyleSheet,
  View,
  Text,
  Focusable
} from '@gopeerproject/ui-kit'

import { EditorContext } from '../contexts'

import { SK } from '../constants'

export const FillAndStrokePopover = ({
  activeObjectProps = { strokeColor: true, fill: true }
}) => {
  const {
    state: {
      color: { border, fill }
    },
    setEditorState
  } = useContext(EditorContext)

  const setFill = (fill: EditorContext['color']['fill']) =>
    setEditorState({ color: { fill } })
  const setBorder = (border: EditorContext['color']['border']) =>
    setEditorState({ color: { border } })

  const styles = useStyleSheet(getFillAndStrokePopoverStyles)

  return (
    <View style={styles.wrapper}>
      {activeObjectProps.fill ? (
        <>
          <View style={styles.section}>
            <View style={styles.sectionTitle}>
              <Text size='md' type='headline'>
                Fill
              </Text>
            </View>

            <View style={styles.dots}>
              {SK.COLOR_PALETTE.map((color) => {
                return (
                  <Dot
                    key={color}
                    onPress={() => setFill(color)}
                    fill={color}
                    stroke={fill === color ? color : null}
                  />
                )
              })}
            </View>
          </View>

          <View style={styles.divider} />
        </>
      ) : null}

      {activeObjectProps.strokeColor ? (
        <>
          <View style={styles.section}>
            <View style={styles.sectionTitle}>
              <Text size='md' type='headline'>
                Stroke
              </Text>
            </View>

            <View style={styles.dots}>
              {SK.COLOR_PALETTE.map((color) => {
                return (
                  <Dot
                    key={color}
                    onPress={() => setBorder(color)}
                    fill={color}
                    stroke={border === color ? color : null}
                  />
                )
              })}
            </View>
          </View>
        </>
      ) : null}
    </View>
  )
}

export const StrokePopover = () => {
  const {
    state: {
      color: { border }
    },
    setEditorState
  } = useContext(EditorContext)

  const setBorder = (border: EditorContext['color']['border']) =>
    setEditorState({ color: { border } })

  const styles = useStyleSheet(getFillAndStrokePopoverStyles)

  return (
    <View style={styles.wrapper}>
      <>
        <View style={styles.section}>
          <View style={styles.sectionTitle}>
            <Text size='md' type='headline'>
              Color
            </Text>
          </View>

          <View style={styles.dots}>
            {SK.COLOR_PALETTE.map((color) => {
              return (
                <Dot
                  key={color}
                  onPress={() => setBorder(color)}
                  fill={color}
                  stroke={border === color ? color : null}
                />
              )
            })}
          </View>
        </View>
      </>
    </View>
  )
}

export const Dot: React.FC<{
  fill: EditorContext['color']['fill']
  stroke: EditorContext['color']['border'] | null
  onPress: () => void
  circle?: boolean
}> = ({ fill, stroke, onPress, circle }) => {
  const styles = useStyleSheet(getFillAndStrokePopoverStyles)
  fill =
    fill === '(0,0,0)'
      ? 'rgba(255, 255, 255, .25)'
      : `rgba(${fill.slice(1, -1)}, 1)`

  return (
    <Focusable
      style={[styles.dotWrapper, circle && styles.dotWrapperCircle]}
      hoverStyle={styles.dotWrapper__hover}
      onPress={onPress}
    >
      {stroke ? (
        <View style={[styles.dot__active, { borderColor: `rgb${stroke}` }]}>
          <View style={[styles.dot, { backgroundColor: fill }]} />
        </View>
      ) : (
        <View style={[styles.dot, { backgroundColor: fill }]} />
      )}
    </Focusable>
  )
}

const getFillAndStrokePopoverStyles = createStyleSheet(({ size, color }) => ({
  wrapper: {},
  section: {
    paddingHorizontal: size.A,
    paddingVertical: size.C,
    gap: size.B
  },
  sectionTitle: {},
  dots: {
    gap: size.A,
    flexDirection: 'row',

    flexWrap: 'wrap'
  },
  dotWrapper: {
    borderRadius: size.G,
    alignItems: 'center',
    justifyContent: 'center',
    width: size.M,
    height: size.M
  },
  dotWrapperCircle: {
    borderRadius: '50%' as any
  },
  dotWrapper__hover: {
    backgroundColor: color.invert2.p10
  },
  dot__active: {
    padding: 1,
    borderRadius: '50%' as any,
    borderWidth: 2,
    alignItems: 'center',
    justifyContent: 'center'
  },
  dot: {
    borderRadius: '50%' as any,
    width: size.J,
    height: size.J
  },
  divider: {
    backgroundColor: color.invert2.p4,
    height: 1,
    width: `calc(100% - ${2 * size.E}px)` as '100%',
    marginVertical: size.C,
    marginHorizontal: 'auto'
  }
}))
