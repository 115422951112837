import { useContext } from 'react'
import { useStyleSheet, View } from '@gopeerproject/ui-kit'

import { EditorContext } from '../contexts'

import { ToolButton } from './ToolButton'
import { getSharedStyles } from './sharedStyles'
import { MainToolbar } from './Main'
import { SelectedItemToolbar } from './SelectedItem'
import { Tooltip } from '../components'
import { isClient, useProfileContext } from '@gopeerproject/chuck'
import { Feature } from '@/screens/classroom/contexts'
import { useClassroomPermissions } from '@/screens/classroom/contexts'

export const InstrumentToolbar = () => {
  const styles = useStyleSheet(getSharedStyles)
  const { canvas } = useContext(EditorContext)

  const activeObject = canvas.current?.getActiveObject()

  return (
    <View style={styles.groups}>
      {activeObject ? <SelectedItemToolbar /> : <MainToolbar />}
    </View>
  )
}

export const UtilityToolbar: React.FC<{
  onDismiss?: () => void
  onZoomIn: () => void
  onZoomOut: () => void
  onUndo?: () => void
  onRedo?: () => void
}> = ({ onDismiss, onZoomIn, onZoomOut, onUndo, onRedo }) => {
  const { profile } = useProfileContext()
  const { checkPermission } = useClassroomPermissions()
  const undoRedoPermission = checkPermission(Feature.SKETCH_UNDO_REDO)

  const styles = useStyleSheet(getSharedStyles)
  return (
    <View style={styles.groups}>
      {!isClient(profile!.type) && onDismiss && (
        <View style={styles.group}>
          <ToolButton icon='dismiss' onPress={() => onDismiss()} />
        </View>
      )}

      {onUndo && onRedo && undoRedoPermission ? (
        <View style={styles.group}>
          <Tooltip placement='left' label='Undo'>
            <ToolButton icon='arrow-undo' onPress={onUndo} />
          </Tooltip>

          <Tooltip placement='left' label='Redo'>
            <ToolButton icon='arrow-redo' onPress={onRedo} />
          </Tooltip>
        </View>
      ) : null}

      <View style={styles.group}>
        <Tooltip placement='left' label='Zoom In'>
          <ToolButton icon='zoom-in' onPress={onZoomIn} />
        </Tooltip>
        <Tooltip placement='left' label='Zoom Out'>
          <ToolButton icon='zoom-out' onPress={onZoomOut} />
        </Tooltip>
      </View>
    </View>
  )
}
