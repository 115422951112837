/** @ref https://codesandbox.io/s/distracted-swirles-jo1pvu?file=/src/App.tsx */
import * as React from 'react'
import {
  useFloating,
  autoUpdate,
  offset,
  // flip,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  useMergeRefs,
  FloatingPortal,
  FloatingFocusManager,
  Placement
} from '@floating-ui/react'
import { createStyleSheet, useStyleSheet, View } from '@gopeerproject/ui-kit'

interface PopoverOptions {
  initialOpen?: boolean
  placement?: Placement
  modal?: boolean
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function usePopover({
  initialOpen = false,
  placement = 'right-start',
  modal,
  open: controlledOpen,
  onOpenChange: setControlledOpen
}: PopoverOptions) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen)
  const [labelId, setLabelId] = React.useState<string | undefined>()
  const [descriptionId, setDescriptionId] = React.useState<string | undefined>()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: 8,
        crossAxis: -8
      }),
      // flip({
      //   crossAxis: placement.includes('-'),
      //   fallbackAxisSideDirection: 'end',
      //   padding: 5
      // }),
      shift({ padding: 5 })
    ]
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId]
  )
}

type ContextType =
  | (ReturnType<typeof usePopover> & {
      setLabelId: React.Dispatch<React.SetStateAction<string | undefined>>
      setDescriptionId: React.Dispatch<React.SetStateAction<string | undefined>>
    })
  | null
const PopoverContext = React.createContext<ContextType>(null)

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext)

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />')
  }

  return context
}

export function Popover({
  children,
  modal = false,
  ...restOptions
}: {
  children: React.ReactNode
} & PopoverOptions) {
  // This can accept any props as options, e.g. `placement`,
  // or other positioning options.
  const popover = usePopover({ modal, ...restOptions })
  return (
    <PopoverContext.Provider value={popover}>
      {children}
    </PopoverContext.Provider>
  )
}

interface PopoverTriggerProps {
  children: React.ReactNode
  asChild?: boolean
}

export const PopoverTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & PopoverTriggerProps
>(function PopoverTrigger({ children, ...props }, propRef) {
  const context = usePopoverContext()
  const childrenRef = (children as any).ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  return (
    <View ref={ref} {...context.getReferenceProps(props)}>
      {children}
    </View>
  )
})

export const PopoverContent = React.forwardRef<
  HTMLDivElement,
  React.HTMLProps<HTMLDivElement>
>(function PopoverContent({ ...props }, propRef) {
  const styles = useStyleSheet(getStyles)
  const { context: floatingContext, ...context } = usePopoverContext()
  const ref = useMergeRefs([context.refs.setFloating, propRef])

  if (!floatingContext.open) return null

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext} modal={context.modal}>
        <View
          ref={ref as any}
          style={[context.floatingStyles as any, styles.content]}
          aria-labelledby={context.labelId}
          aria-describedby={context.descriptionId}
          {...context.getFloatingProps(props)}
        >
          {props.children}
        </View>
      </FloatingFocusManager>
    </FloatingPortal>
  )
})

const getStyles = createStyleSheet(({ size, color }) => ({
  content: {
    paddingHorizontal: size.C,
    paddingVertical: size.E,
    backgroundColor: color.bg.v3,
    borderWidth: 1,
    borderRadius: size.G,
    borderColor: color.invert2.p4,
    minWidth: 260,
    zIndex: 2
  }
}))
