import { fabric } from 'fabric'

import { Graph } from './Graph'
import { Formula } from './Formula'
import { FormulaGraphToolModal } from './Modal'

const registerCustomElements = () => {
  // @ts-ignore
  fabric.Graph = Graph
  // @ts-ignore
  fabric.Graph.fromObject = function (object: any, callback: any) {
    return fabric.Object._fromObject('Graph', object, callback)
  }

  // @ts-ignore
  fabric.Formula = Formula
  // @ts-ignore
  fabric.Formula.fromObject = function (object: any, callback: any) {
    return fabric.Object._fromObject('Formula', object, callback)
  }
}

export { Graph, Formula, registerCustomElements, FormulaGraphToolModal }
