import { useAuth } from '@/contexts'
import {
  useClassroomDispatch,
  useClassroomState
} from './ClassroomStateContext'
import { MediaProvider as MP } from '@gopeerproject/web-kit'
import { useCallback } from 'react'

export const MediaProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const { profile } = useAuth()
  const classroomDispatch = useClassroomDispatch()
  const classroomState = useClassroomState()

  const onError = useCallback(
    (error: Error) => {
      console.error(`PUBLICATION ERROR: ${error.message}`, error)
      // Sentry.track(error)
      classroomDispatch({ type: 'set_error', payload: error })
    },
    [classroomDispatch]
  )

  const onRoomConnected = useCallback(() => {
    // ClassroomManager.updateParticipant(state)
  }, [])

  const onRoomDisconnected = useCallback(() => {
    // ClassroomManager.updateParticipant({
    //   audio: false,
    //   video: false,
    //   screen: false
    // })
  }, [])

  const onBackgroundLoading = useCallback(
    (payload: boolean) =>
      classroomDispatch({
        type: 'set_is_background_loading',
        payload
      }),
    [classroomDispatch]
  )

  return (
    <MP
      onError={onError}
      id={profile!.id}
      onRoomConnected={onRoomConnected}
      onRoomDisconnected={onRoomDisconnected}
      enableBackgrounds
      selectedBackground={classroomState.background}
      onBackgroundLoading={onBackgroundLoading}
    >
      {children}
    </MP>
  )
}
