import React, { useState } from 'react'
import { ScrollView } from 'react-native'
import {
  useMediaContext,
  VideoTrack,
  useIsRecording,
  PortalModal
} from '@gopeerproject/web-kit'
import {
  createStyleSheet,
  Divider,
  Icon,
  useStyleSheet,
  View,
  Text,
  Focusable
} from '@gopeerproject/ui-kit'

import { ErrorMessage } from './ErrorMessage'
import {
  AudioOutputList,
  VideoInputList,
  AudioTrackTester,
  AudioInputSetting
} from '../Media'
import { Backgrounds } from './Backgrounds'

import { isClient } from '@/utils'
import { useAuth } from '@/contexts'
import { useClassroomDispatch } from '../../contexts'

const TABS = {
  audio: { text: 'Audio', icon: 'headphones' },
  video: { text: 'Video', icon: 'video' },
  backgrounds: { text: 'Backgrounds', icon: 'video-background-effect' },
  recordings: { text: 'Recordings', icon: 'video-clip' }
} as const

export const Settings = () => {
  const styles = useStyleSheet(getStyles)
  const [tab, setTab] = useState('audio')
  const { localTracks, error } = useMediaContext()
  const localVideoTrack = localTracks.find((track) => track.kind === 'video')
  const isRecording = useIsRecording()
  const { profile } = useAuth()
  const dispatch = useClassroomDispatch()
  return (
    <PortalModal
      onClose={() => dispatch({ type: 'close_settings_modal' })}
      title='Settings'
    >
      <View style={styles.container}>
        <View style={styles.navigation}>
          {Object.entries(TABS).map(([value, { text, icon }]) => (
            <Focusable
              key={value}
              onPress={() => setTab(value)}
              style={[styles.navItem, value === tab && styles.navItemActive]}
              hoverStyle={styles.navItemHover}
            >
              <Icon name={icon} filled={value === tab} size={24} />
              <Text
                type='body'
                size='lg'
                weight={value === tab ? 'semibold' : 'regular'}
                style={styles.navItemText}
              >
                {text}
              </Text>
              <Icon name='chevron-right' filled={value === tab} size={16} />
            </Focusable>
          ))}
        </View>

        <Divider style={styles.divider} type='vertical' />

        <ScrollView
          style={styles.content}
          contentContainerStyle={styles.contentContainer}
          showsVerticalScrollIndicator={false}
        >
          {!!error && <ErrorMessage error={error} />}
          {tab === 'audio' && (
            <>
              <View style={styles.speakers}>
                <Text type='headline' size='md'>
                  Speakers
                </Text>
                <AudioOutputList />
                <AudioTrackTester />
              </View>
              <View style={styles.microphone}>
                <Text type='headline' size='md'>
                  Microphone
                </Text>
                <AudioInputSetting />
              </View>
            </>
          )}
          {tab === 'video' && (
            <>
              {localVideoTrack && (
                <React.Fragment>
                  <View style={styles.videoPreview}>
                    <VideoTrack isLocal track={localVideoTrack} />
                  </View>
                </React.Fragment>
              )}
              <Text type='headline' size='md' style={styles.videoHeadline}>
                Camera
              </Text>
              <VideoInputList />
            </>
          )}
          {tab === 'backgrounds' && (
            <>
              {localVideoTrack && (
                <React.Fragment>
                  <View style={styles.videoPreview}>
                    <VideoTrack isLocal track={localVideoTrack} />
                  </View>
                  <Text type='headline' size='md' style={styles.videoHeadline}>
                    Backgrounds
                  </Text>
                  <Backgrounds />
                </React.Fragment>
              )}
            </>
          )}
          {tab === 'recordings' && (
            <>
              <Text type='headline' size='md' style={styles.videoHeadline}>
                Recordings
              </Text>
              <Text type='body' size='md' weight='regular'>
                {isRecording
                  ? 'Current session is being recorded.'
                  : 'Current session is not being recorded.'}
              </Text>
              {isClient(profile!.type) ? (
                <Text type='body' size='md' weight='regular'>
                  You can manage opt in & opt out preferences from your settings
                  anytime.
                </Text>
              ) : null}
            </>
          )}
        </ScrollView>
      </View>
    </PortalModal>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: { flexDirection: 'row' },
  divider: { width: 1, backgroundColor: color.invert2.p4 },
  navigation: {
    width: 270,
    paddingVertical: size.K,
    paddingHorizontal: size.F,
    gap: size.B,
    alignItems: 'stretch'
  },
  navItem: {
    flexDirection: 'row',
    borderRadius: size.E,
    paddingVertical: size.G,
    paddingHorizontal: size.E,
    gap: size.C,
    alignItems: 'center'
  },
  navItemHover: { backgroundColor: color.invert2.p4 },
  navItemActive: { backgroundColor: color.invert2.p4 },
  navItemText: { flexGrow: 1, textAlign: 'left' },
  content: {
    width: 450,
    height: 520,
    padding: size.K,
    textAlign: 'left',
    overflow: 'auto' as 'visible'
  },
  speakers: { gap: size.C, marginBottom: size.K },
  microphone: { gap: size.C },
  videoPreview: {
    borderRadius: size.G,
    marginBottom: size.K,
    overflow: 'hidden'
  },
  videoHeadline: { marginBottom: size.C },
  contentContainer: {}
}))
