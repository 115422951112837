import { CacheKeys } from '@/constants'
import { apiClient, queryClient } from '@/utils'

export enum WaitingRoomStatus {
  WAITING = 'waiting',
  ADMITTED = 'admitted',
  REJECTED = 'rejected'
}

export enum MediaTypes {
  VIDEO = 'video',
  AUDIO = 'audio'
}

export enum MediaRestrictionStatus {
  RESTRICTED = 'restricted',
  UNRESTRICTED = 'unrestricted'
}

export type TMemberControls = {
  status: WaitingRoomStatus
  video: MediaRestrictionStatus
  audio: MediaRestrictionStatus
  participants: {
    id: string
    status: WaitingRoomStatus
    video: MediaRestrictionStatus
    audio: MediaRestrictionStatus
  }[]
}

export const queryMemberControls = (id: string) =>
  queryClient.fetchQuery({
    queryKey: [CacheKeys.memberControls, id],
    queryFn: () =>
      apiClient
        .get({ url: `/classroom_groups/${id}` })
        .then((res) => res.data as TMemberControls),
    retry: false,
    staleTime: Infinity
  })
