const order = ['Cmd', 'Alt', 'Ctrl', 'Shift']
const keyByPlatform = /Mac/i.test(navigator.platform) ? 'Cmd' : 'Ctrl'

export const normalizeShortcut = (shortcut: string) =>
  shortcut
    .split('+')
    .map((key) => (key === 'CtrlOrCmd' ? keyByPlatform : key))
    .sort((a, b) => order.indexOf(b) - order.indexOf(a))
    .join('+')
    .toLowerCase()

export const triggerShortcut = (shortcut: string) => {
  const normalized = normalizeShortcut(shortcut)
  const keys = normalized
    .replace('cmd', '')
    .replace('ctrl', '')
    .replace('alt', '')
    .replace('shift', '')
    .split('+')
  const eventOptions: Record<string, boolean> = {}

  if (normalized.includes('cmd')) eventOptions.metaKey = true
  if (normalized.includes('ctrl')) eventOptions.ctrlKey = true

  keys.filter(Boolean).forEach((key) => {
    window.dispatchEvent(new KeyboardEvent('keydown', { key, ...eventOptions }))
  })
}
