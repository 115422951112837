import {
  ClassroomStep,
  useClassroomDispatch,
  useClassroomState
} from './contexts'
import { useEffect, useRef } from 'react'
import { useMediaContext } from '@gopeerproject/web-kit'
import { Onboarding } from './components/Onboarding'
import { Main } from './components/Main'
import { useClassroomQuery, useMemberControlsQuery } from './hooks'
import { AppointmentStatus } from '@gopeerproject/chuck'
import { useNavigate } from 'react-router-dom'
import { Sentry } from '@/utils'
import { WaitingRoomStatus } from '@/data'
import { useClassroomManager } from './hooks/useClassroomManager'

export const ClassroomScreen = () => {
  const { step } = useClassroomState()
  const dispatch = useClassroomDispatch()
  const { room, localTracks } = useMediaContext()
  const tracks = useRef(localTracks)
  const navigate = useNavigate()

  useClassroomQuery({
    onSuccess: (classroom) => {
      switch (classroom.status) {
        case AppointmentStatus.InProgress:
          break
        case AppointmentStatus.Confirmed:
          navigate('not_in_progress', { replace: true })
          break
        case AppointmentStatus.Pending:
        case AppointmentStatus.Completed:
          navigate('closed', { replace: true })
          break
        default:
          Sentry.track(new Error('invalid classroom update'), classroom)
      }
    }
  })
  useMemberControlsQuery({
    onSuccess: (memberControls) => {
      switch (memberControls.status) {
        case WaitingRoomStatus.REJECTED:
          navigate('rejected', { replace: true })
          break
        case WaitingRoomStatus.WAITING:
          dispatch({ type: 'set_step', payload: ClassroomStep.ONBOARDING })
          break
        default:
          Sentry.track(
            new Error('invalid member_controls update'),
            memberControls
          )
      }
    }
  })

  // Audio/Video specific cleanups
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      tracks.current.forEach((track) => track.stop())
    }
  }, [])

  useEffect(() => {
    return () => {
      if (room?.disconnect) room.disconnect()
    }
  }, [room])

  const { destroy } = useClassroomManager()

  useEffect(() => {
    return () => {
      destroy()
    }
  }, [destroy])

  switch (step) {
    case ClassroomStep.ONBOARDING:
      return <Onboarding />
    case ClassroomStep.READY:
      return <Main />
    default:
      return null
  }
}
