import { createContext, useContext, useMemo, useState } from 'react'
import { Conversation, useConversation } from '@gopeerproject/chuck'
import { assertContext } from '@gopeerproject/ui-kit'

type Visibility = 'public' | 'private'

const ConversationContext = createContext<
  | {
      visibility: Visibility
      setVisibility: React.Dispatch<React.SetStateAction<Visibility>>
      data: Conversation | undefined
      isLoading: boolean
    }
  | undefined
>(undefined)

export const ConversationContextProvider: React.FC<{
  children: React.ReactNode
  id: string
}> = ({ children, id }) => {
  const [visibility, setVisibility] = useState<Visibility>('public')
  const { data, isLoading } = useConversation(id)

  const value = useMemo(
    () => ({
      visibility,
      setVisibility,
      data,
      isLoading
    }),
    [visibility, data, isLoading]
  )

  return (
    <ConversationContext.Provider value={value}>
      {children}
    </ConversationContext.Provider>
  )
}

export const useConversationContext = () => {
  return assertContext(
    useContext(ConversationContext)!,
    'useConversationContext',
    'ConversationContextProvider'
  )
}
