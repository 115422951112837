import { createStyleSheet } from '@gopeerproject/ui-kit'

export const getSharedStyles = createStyleSheet(({ size, color }) => ({
  groups: {
    flexDirection: 'column',
    gap: size.C
  },
  group: {
    flexDirection: 'column',

    backgroundColor: color.bg.v3,

    borderColor: color.invert2.p4,
    borderWidth: 1,
    borderRadius: size.L + size.B,
    boxShadow: '0px 8px 42px rgba(28, 29, 40, 0.1)'
  },
  divider: {
    backgroundColor: color.invert2.p4,
    height: 1,
    width: `calc(100% - ${2 * size.D}px)` as '100%',
    marginVertical: size.C,
    marginHorizontal: 'auto'
  }
}))
