// @TODO :: red when less than 10 mins remaining
import { useEffect, useRef, useState } from 'react'
import {
  createStyleSheet,
  Focusable,
  Loader,
  Text,
  useStyleSheet,
  useThemeContext,
  View
} from '@gopeerproject/ui-kit'
import { differenceInMinutes, parseISO } from 'date-fns'
import { Overtime } from './Overtime'
import { useClassroomQuery } from '../../hooks'
import { isClient } from '@gopeerproject/chuck'
import { useAuth } from '@/contexts'

const getTimeDiff = (time: string) =>
  differenceInMinutes(new Date(), parseISO(time))

export const Timer = () => {
  const styles = useStyleSheet(getStyles)
  const [isOpen, setIsOpen] = useState(false)
  const { color, size } = useThemeContext()

  const { data: classroom } = useClassroomQuery()
  const { profile } = useAuth()
  const { time, duration = 60, overtime = 0 } = classroom!
  const [timePassed, setTimePassed] = useState(() => getTimeDiff(time))

  useInterval(() => {
    setTimePassed(getTimeDiff(time))
  }, 1000)

  const totalDuration = duration + overtime
  const circumference = Math.PI * size.M

  const isExpiring = totalDuration - timePassed <= 10

  return (
    <>
      <Focusable
        style={[styles.container, isExpiring && styles.containerExpiring]}
        hoverStyle={styles.containerHover}
        onPress={() => {
          if (classroom?.type === 'group' && isClient(profile!.type)) return
          setIsOpen(!isOpen)
        }}
        // @ts-expect-error
        onMouseDown={(e: MouseEvent) => {
          if (isOpen) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      >
        <View style={styles.progressContainer}>
          <svg
            width={size.M}
            height={size.M}
            viewBox={`0 0 ${size.M} ${size.M}`}
            style={{ transform: 'rotate(-90deg)' }}
          >
            <circle
              cx={size.M / 2}
              cy={size.M / 2}
              r={size.M / 2 - size.B / 2}
              fill='none'
              stroke={isExpiring ? color.negative.solid : color.primary.solid}
              strokeWidth={size.B}
              strokeDasharray={circumference}
              strokeDashoffset={
                circumference *
                (Math.max(totalDuration - timePassed, 0) / totalDuration)
              }
              strokeLinecap='round'
              style={{ transition: 'all 1s ease-in-out' }}
            />
          </svg>
        </View>
        <Text style={styles.text} type='body' weight='semibold' size='md'>
          {timePassed ? formatTime(totalDuration - timePassed) : <Loader />}
        </Text>
      </Focusable>
      {isOpen && (
        <Overtime
          onClose={() => setIsOpen(false)}
          timeLeft={formatTime(totalDuration - timePassed)}
        />
      )}
    </>
  )
}

function formatTime(time: number) {
  if (time < 0) return `0m`
  if (time < 60) return `${time}m`
  else if (time % 60 === 0) return `${time / 60}h`
  else return `${Math.floor(time / 60)}h ${time % 60}m`
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: {
    width: size.M,
    height: size.M,
    backgroundColor: color.bg.v3,
    position: 'relative',
    // @ts-expect-error
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: color.invert2.p4
  },
  containerExpiring: {
    backgroundColor: color.negative.ghostHover
  },
  containerHover: { backgroundColor: color.invert2.p10 },
  progressContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    alignItems: 'center'
  },
  text: { fontSize: size.D, lineHeight: size.E }
}))

const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef<(() => void) | undefined>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    function tick() {
      savedCallback.current && savedCallback.current()
    }

    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}
