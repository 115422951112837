import { useEffect, useRef } from 'react'
import { Button } from '@gopeerproject/ui-kit'
import { useMediaContext } from '@gopeerproject/web-kit'

export const AudioTrackTester = () => {
  const {
    activeAudioDevice: { id: activeSinkId }
  } = useMediaContext()
  const audio = useRef<HTMLAudioElement | undefined>()

  useEffect(() => {
    if (activeSinkId) {
      if (!audio.current)
        audio.current = new Audio(
          'https://endeis.s3.us-west-2.amazonaws.com/audio/speaker.mp3'
        )
      if (typeof audio.current.setSinkId === 'function')
        audio.current.setSinkId(activeSinkId)
    }
  }, [activeSinkId])

  return (
    <Button
      type='secondary'
      accent='neutral'
      size='xs'
      onPress={() => audio.current?.play()}
      disabled={!activeSinkId}
      text='Test speakers'
    />
  )
}
