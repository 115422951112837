import JXG, { Board } from 'jsxgraph'
import evaluatex from 'evaluatex/dist/evaluatex'

const defaultXAxisPoints = [
  [0, 0],
  [1, 0]
]
const defaultYAxisPoints = [
  [0, 0],
  [0, 1]
]

export const createEmptyBoard = (containerId: string, renderTicks = false) => {
  // Theming reference
  // https://github.com/jsxgraph/jsxgraph/blob/master/src/themes/dark.js
  JXG.Options.axis.strokeColor = 'rgba(128, 128, 128, 0.26)'
  JXG.Options.axis.highlightStrokeColor = 'rgba(128, 128, 128, 0.26)'
  if (JXG.Options.axis.ticks && JXG.Options.axis.ticks.label) {
    JXG.Options.axis.ticks.strokeColor = 'rgba(128, 128, 128, 0.26)'
    JXG.Options.axis.ticks.highlightStrokeColor = 'rgba(128, 128, 128, 0.26)'
    JXG.Options.axis.ticks.label.strokeColor = 'rgba(128, 128, 128, 0.26)'
    JXG.Options.axis.ticks.label.highlightStrokeColor =
      'rgba(128, 128, 128, 0.26)'
  }
  JXG.Options.curve.strokeColor = '#D764B9'
  JXG.Options.curve.highlightStrokeColor = '#D764B9'

  const board = JXG.JSXGraph.initBoard(containerId, {
    boundingbox: [-5, 5, 5, -5],
    axis: false,
    zoom: {
      factorX: 1.25,
      factorY: 1.25,
      needShift: true
    },
    showCopyright: false,
    showNavigation: false
  })

  const xAxis = board.create('axis', defaultXAxisPoints, {
    name: 'x',
    lastArrow: false,
    ticks: {
      strokeOpacity: renderTicks ? 0.2 : 0,
      minorTicks: 0,
      label: {
        position: 'rt'
      }
    }
  })

  const yAxis = board.create('axis', defaultYAxisPoints, {
    name: 'y',
    lastArrow: false,
    ticks: {
      strokeOpacity: renderTicks ? 0.2 : 0,
      minorTicks: 0,
      label: {
        position: 'rt'
      }
    }
  })

  return { board, axis: { xAxis, yAxis } }
}

export const createFunctionGraph = (board: Board, latex: string) => {
  try {
    const fn = evaluatex(latex, {}, { latex: true })

    return board.create('functiongraph', [(x: any) => fn({ x })])
  } catch (e) {
    return null
  }
}

export const createContainer = ({
  id,
  width,
  height
}: {
  id: string
  width?: number
  height?: number
}) => {
  const container = document.createElement('div')
  const styles = `
    position: absolute;
    pointer-events: none;
    ${width ? `width: ${width}px;` : ''}
    ${height ? `height: ${width}px;` : ''}
  `

  container.setAttribute('id', id)
  container.setAttribute('style', styles)
  document.querySelector('.canvas-container')!.appendChild(container)

  return container
}
