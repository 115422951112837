import { useAsyncError, useNavigate } from 'react-router-dom'
import { GlobalMessage } from './GlobalMessage'
import { useEffect, useState } from 'react'
import { redirectToCore, Sentry } from '@/utils'
import {
  Button,
  createStyleSheet,
  Loader,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'

type Redirect = { redirect: string }
const isRedirect = (error: unknown): error is Redirect => {
  if (typeof error === 'object' && typeof (error as any).redirect === 'string')
    return true
  return false
}

export const GlobalError = () => {
  const styles = useStyleSheet(getStyles)
  const error = useAsyncError()
  const navigate = useNavigate()

  const [ref, setRef] = useState('')
  useEffect(() => {
    if (isRedirect(error)) {
      navigate(error.redirect, { replace: true })
    } else {
      setRef(Sentry.captureException(error as Error))
    }
  }, [error, navigate])

  if (isRedirect(error)) {
    return <Loader full />
  }

  return (
    <View style={styles.container}>
      <GlobalMessage
        emoji='😕'
        title='Something went wrong'
        description={`Please try refreshing the page or contact our support. ${
          ref.length > 0 ? `(Error Ref: ${ref})` : ''
        }`}
      />
      <Button
        type='primary'
        centered
        size='lg'
        accent='default'
        onPress={() => {
          redirectToCore()
        }}
        text='Back to dashboard'
        style={styles.button}
      />
    </View>
  )
}

const getStyles = createStyleSheet(({ size }) => ({
  container: { flexGrow: 1, justifyContent: 'center', alignItems: 'center' },
  button: { marginTop: size.K }
}))
