import { wrapCreateBrowserRouter } from '@sentry/react'
import { createBrowserRouter, useLocation } from 'react-router-dom'
import { DemoClassroom } from './DemoClassroom'
import { Session, loader as sessionLoader } from './Session'
import { RootLayout } from './RootLayout'
import { HomePage } from './Home'
import { GenericError } from './GenericError'
import { SessionClosed } from './SessionClosed'
import { SessionNotInProgress } from './SessionNotInProgress'
import { SessionAdmissionRejected } from './SessionAdmissionRejected'

const Placeholder = () => {
  const location = useLocation()
  return <h1>TODO: {location.pathname}</h1>
}

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

export const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: <RootLayout />,
    errorElement: <GenericError />,
    children: [
      {
        // Route for when user pushes end-call button
        path: 'session/:id/completed',
        element: <Placeholder />
      },

      {
        path: 'session/:id/not_in_progress',
        element: <SessionNotInProgress />
      },
      {
        path: 'session/:id/rejected',
        element: <SessionAdmissionRejected />
      },
      {
        path: 'session/:id/closed',
        element: <SessionClosed />
      },
      {
        path: 'session/:id',
        element: <Session />,
        loader: sessionLoader
      },
      {
        path: '/demo',
        element: <DemoClassroom />
      },
      {
        path: '/',
        element: <HomePage />
      },
      {
        path: '*',
        element: <HomePage />
      }
    ]
  }
])
