import {
  createStyleSheet,
  Icon,
  Text,
  useStyleSheet,
  useThemeContext,
  View
} from '@gopeerproject/ui-kit'
import { useAudioOutputList } from '@gopeerproject/web-kit'

import { Dropdown } from '@/components'

export const AudioOutputList = () => {
  const styles = useStyleSheet(getStyles)
  const { color } = useThemeContext()

  const { devices, onDeviceSelect, activeOutput } = useAudioOutputList()

  return (
    <Dropdown
      data={
        devices.length > 1
          ? devices.map((device) => {
              return {
                onPress: () => onDeviceSelect(device.id),
                text: device.label,
                ...(device.id === activeOutput.id
                  ? {
                      rightIcon: {
                        name: 'checkmark',
                        color: color.primary.solid
                      }
                    }
                  : {})
              }
            })
          : [{ text: 'System default audio output' }]
      }
    >
      <View style={styles.container}>
        <Icon name='speaker-high' size={21} filled />
        <Text type='body' size='lg' weight='regular' style={styles.text}>
          {activeOutput.label || 'System default audio output'}
        </Text>
        <Icon name='chevron-down' size={21} />
      </View>
    </Dropdown>
  )
}

const getStyles = createStyleSheet(({ color, size }) => ({
  container: {
    flexDirection: 'row',
    padding: size.F,
    gap: size.C,
    borderRadius: size.C,
    backgroundColor: color.bg.v1,
    borderColor: color.invert2.p4,
    borderWidth: 1,
    borderStyle: 'solid',
    alignItems: 'center'
  },
  text: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    flexGrow: 1
  }
}))
