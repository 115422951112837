import isEqual from 'lodash/isEqual'

const property = (e, p) =>
  parseFloat(window.getComputedStyle(e, null).getPropertyValue(p))

const contentHeight = (e) =>
  e.clientHeight - property(e, 'padding-top') - property(e, 'padding-bottom')

const contentWidth = (e) =>
  e.clientWidth - property(e, 'padding-left') - property(e, 'padding-right')

const isHorizontal = (e) =>
  window
    .getComputedStyle(e, null)
    .getPropertyValue('writing-mode')
    .includes('horizontal')

const borderBoxSize = (e) => ({
  blockSize: isHorizontal(e) ? e.offsetHeight : e.offsetWidth,
  inlineSize: isHorizontal(e) ? e.offsetWidth : e.offsetHeight
})

const contentBoxSize = (e) => ({
  blockSize: isHorizontal(e) ? contentHeight(e) : contentWidth(e),
  inlineSize: isHorizontal(e) ? contentWidth(e) : contentHeight(e)
})

const contentRect = (e) => ({
  top: property(e, 'padding-top'),
  left: property(e, 'padding-left'),
  height: contentHeight(e),
  width: contentWidth(e)
})

class _ResizeObserverPony {
  constructor(callback, delay = 100) {
    this.callback = callback
    this.entries = []
    this.interval = null
    this.delay = delay
  }

  observe(target, options = {}) {
    const box = ['content-box', 'border-box'].includes(options.box)
      ? options.box
      : 'content-box'
    this.entries.push({
      target: target,
      box,
      newSize: null
    })
    if (this.interval === null)
      this.interval = setInterval(() => {
        this.entries = this.entries.map((e) => ({
          ...e,
          lastSize: e.newSize,
          newSize: (e.box === 'content-box' ? contentBoxSize : borderBoxSize)(
            e.target
          )
        }))
        const diff = this.entries
          .filter((e) => !isEqual(e.newSize, e.lastSize))
          .map(({ target }) => ({
            borderBoxSize: borderBoxSize(target),
            contentBoxSize: contentBoxSize(target),
            contentRect: contentRect(target),
            target
          }))

        if (diff.length > 0) this.callback(diff)
      }, this.delay)
  }

  unobserve(entry) {
    this.entries = this.entries.filter((e) => e !== entry)
    if (this.entries.length === 0 && this.interval !== null) {
      clearInterval(this.interval)
      this.interval = null
    }
  }

  disconnect() {
    this.entries = []
    if (this.interval !== null) {
      clearInterval(this.interval)
      this.interval = null
    }
  }
}

export const ResizeObserverPony = Object.prototype.hasOwnProperty.call(
  window,
  'ResizeObserver'
)
  ? ResizeObserver
  : _ResizeObserverPony
