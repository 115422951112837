import { Image } from 'react-native'
import {
  createStyleSheet,
  useStyleSheet,
  View,
  Text
} from '@gopeerproject/ui-kit'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts'
import { isTutor } from '@gopeerproject/chuck'
import { useClassroomManagerRef } from '../../contexts'
import { TProfile } from '@/types'

const Cursor: React.FC<{
  image?: string
  name?: string
  position?: { top: number; left: number }
}> = ({ image = '', name = '', position = {} }) => {
  const styles = useStyleSheet(getStyles)
  return (
    <View
      style={[
        styles.cursor,
        {
          left: position.left,
          top: position.top
        }
      ]}
    >
      <View style={styles.cursorAvatar}>
        {image ? (
          <Image style={styles.cursorAvatarImg} source={image as any} />
        ) : (
          <Text type='body' size='sm' weight='semibold'>
            {name.split(' ').reduce((acc, v) => acc + v.charAt(0), '')}
          </Text>
        )}
      </View>
      <View style={styles.cursorLine} />
      <View
        style={[
          styles.cursorPointer,
          { boxShadow: '0px 0px 0px 3px rgba(249, 185, 88, 0.15)' } as any
        ]}
      />
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  cursor: {
    position: 'absolute',
    alignItems: 'center'
  },
  cursorAvatar: {
    padding: size.A,
    backgroundColor: color.warning.solid,
    borderRadius: '50%' as any
  },
  cursorAvatarImg: {
    width: size.G,
    height: size.G,
    borderRadius: '50%' as any
  },
  cursorLine: {
    width: size.A,
    backgroundColor: color.warning.solid,
    height: size.C - size.A
  },
  cursorPointer: {
    marginTop: -1,
    width: size.B,
    height: size.B,
    borderRadius: '50%' as any,
    backgroundColor: color.warning.solid
  }
}))

export const Cursors = () => {
  const auth = useAuth()
  const profile = auth.profile!

  const showStudentCursors = isTutor(profile.type)
  type AwarenessParticipant = Pick<
    TProfile,
    'id' | 'name' | 'image' | 'type'
  > & { clientId: string; top: number; left: number }

  const [participants, setParticipants] = useState<AwarenessParticipant[]>([])

  const classroomManagerRef = useClassroomManagerRef()

  useEffect(() => {
    const observe = (clientId: string, data: AwarenessParticipant) => {
      setParticipants((prevParticipants) => {
        if (!data) {
          return prevParticipants.filter((p) => p.clientId !== clientId)
        }

        const nextParticipants = prevParticipants.filter(
          (p) => p.id !== data.id && p.clientId !== clientId
        )

        nextParticipants.push({
          ...data,
          clientId
        })

        return nextParticipants
      })
    }

    const cm = classroomManagerRef.current!

    cm.observe('awareness', observe as any)
    return () => {
      cm.unobserve('awareness', observe as any)
    }
  }, [classroomManagerRef])

  return (
    <>
      {participants.map((participant) => {
        if (showStudentCursors || participant.type === 'tutor')
          return (
            <Cursor
              key={participant.id}
              image={participant.image}
              name={participant.name}
              position={{
                top: participant.top,
                left: participant.left
              }}
            />
          )

        return null
      })}
    </>
  )
}
