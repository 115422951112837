import { useContext, useReducer } from 'react'
import {
  useStyleSheet,
  createStyleSheet,
  View,
  Text
} from '@gopeerproject/ui-kit'
import { ToolButton } from './ToolButton'

import { SK } from '../constants'
import { EditorContext } from '../contexts'

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
  usePopoverContext,
  Tooltip
} from '../components'

import { getSharedStyles } from './sharedStyles'
import { StrokePopover, Dot } from './FillAndStrokePopover'
import { ThicknessAndStylePopover } from './ThicknessAndStylePopover'
import { Feature, useClassroomPermissions } from '@/screens/classroom/contexts'

export const MainToolbar = () => {
  const styles = useStyleSheet(getSharedStyles)

  const {
    state: { tool, color },
    setEditorState
  } = useContext(EditorContext)

  const { checkPermission } = useClassroomPermissions()
  const canErase = checkPermission(Feature.SKETCH_ERASER)

  type PopoverState = {
    shapeSelector: boolean
    thicknessAndStyle: boolean
    stroke: boolean
  }

  type PopoverAction =
    | {
        type: 'toggle'
        popover: keyof PopoverState
      }
    | {
        type: 'set'
        popover: keyof PopoverState
        value: boolean
      }

  const [popovers, dispatch] = useReducer(
    (state: PopoverState, action: PopoverAction) => {
      const { popover, type } = action
      switch (type) {
        case 'toggle':
          return { ...state, [popover]: !state[popover] }
        case 'set':
          return { ...state, [popover]: action.value }
        default:
          return state
      }
    },
    {
      shapeSelector: false,
      thicknessAndStyle: false,
      stroke: false
    }
  )

  return (
    <View style={styles.group}>
      <Tooltip label='Move'>
        <ToolButton
          icon='cursor'
          active={tool === SK.TOOLS.SELECT}
          onPress={() => setEditorState({ tool: SK.TOOLS.SELECT })}
        />
      </Tooltip>

      <Tooltip label='Hand Tool'>
        <ToolButton
          icon='hand'
          active={tool === SK.TOOLS.HAND}
          onPress={() => setEditorState({ tool: SK.TOOLS.HAND })}
        />
      </Tooltip>

      <Tooltip label='Pencil'>
        <ToolButton
          icon='edit'
          active={tool === SK.TOOLS.PENCIL}
          onPress={() => setEditorState({ tool: SK.TOOLS.PENCIL })}
        />
      </Tooltip>

      <Popover
        open={popovers.shapeSelector}
        onOpenChange={(isOpen) =>
          dispatch({ type: 'set', popover: 'shapeSelector', value: isOpen })
        }
      >
        <PopoverTrigger>
          <Tooltip label='Shape'>
            <ToolButton
              icon={getShapeSelectorIcon(tool)}
              active={isShapeSelectorActive(tool)}
              onPress={() =>
                dispatch({ type: 'toggle', popover: 'shapeSelector' })
              }
            />
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent>
          <ShapeSelectorPopup
            tool={tool}
            onSelect={(t) => setEditorState({ tool: t })}
          />
        </PopoverContent>
      </Popover>

      <Tooltip label='Text'>
        <ToolButton
          icon='text'
          active={tool === SK.TOOLS.TEXT}
          onPress={() => setEditorState({ tool: SK.TOOLS.TEXT })}
        />
      </Tooltip>

      <Tooltip label='Image'>
        <ToolButton
          icon='image'
          active={tool === SK.TOOLS.IMAGE}
          onPress={() => setEditorState({ tool: SK.TOOLS.IMAGE })}
        />
      </Tooltip>

      <Tooltip label='Formula'>
        <ToolButton
          icon='math-formula'
          active={tool === SK.TOOLS.MATH}
          onPress={() => setEditorState({ tool: SK.TOOLS.MATH })}
        />
      </Tooltip>

      {canErase ? (
        <Tooltip label='Eraser'>
          <ToolButton
            icon='eraser'
            active={tool === SK.TOOLS.ERASER}
            onPress={() => setEditorState({ tool: SK.TOOLS.ERASER })}
          />
        </Tooltip>
      ) : null}

      <Popover
        open={popovers.thicknessAndStyle}
        onOpenChange={(isOpen) =>
          dispatch({ type: 'set', popover: 'thicknessAndStyle', value: isOpen })
        }
      >
        <PopoverTrigger>
          <Tooltip label='Thickness and style'>
            <ToolButton
              icon='line-style'
              onPress={() =>
                dispatch({ type: 'toggle', popover: 'thicknessAndStyle' })
              }
            />
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent>
          <ThicknessAndStylePopover />
        </PopoverContent>
      </Popover>

      <Popover
        open={popovers.stroke}
        onOpenChange={(isOpen) =>
          dispatch({ type: 'set', popover: 'stroke', value: isOpen })
        }
      >
        <PopoverTrigger>
          <Tooltip label='Color'>
            <Dot
              fill={color.fill}
              stroke={color.border}
              circle
              onPress={() => dispatch({ type: 'toggle', popover: 'stroke' })}
            />
          </Tooltip>
        </PopoverTrigger>
        <PopoverContent>
          <StrokePopover />
        </PopoverContent>
      </Popover>
    </View>
  )
}

const ShapeSelectorPopup: React.FC<{
  tool: EditorContext['tool']
  onSelect: (tool: EditorContext['tool']) => void
}> = ({ tool, onSelect }) => {
  const styles = useStyleSheet(getShapeSelectorPopupStyles)
  const { setOpen } = usePopoverContext()
  return (
    <View style={styles.wrapper}>
      <View style={styles.header}>
        <Text size='md' type='headline'>
          Choose shape
        </Text>
      </View>
      <View style={styles.buttons}>
        {[SK.TOOLS.LINE, SK.TOOLS.RECT, SK.TOOLS.CIRCLE, SK.TOOLS.TRIANGLE].map(
          (t) => {
            const active = tool === t
            const icon = getShapeSelectorIcon(t)

            return (
              <ToolButton
                key={t}
                active={active}
                icon={icon}
                onPress={() => {
                  onSelect(t)
                  setOpen(false)
                }}
              />
            )
          }
        )}
      </View>
    </View>
  )
}

const getShapeSelectorIcon = (tool: EditorContext['tool']) => {
  switch (tool) {
    case SK.TOOLS.LINE:
      return 'line'
    case SK.TOOLS.RECT:
      return 'square-rounded'
    case SK.TOOLS.CIRCLE:
      return 'circle'
    case SK.TOOLS.TRIANGLE:
      return 'triangle'
    default:
      return 'shapes'
  }
}

const isShapeSelectorActive = (tool: EditorContext['tool']) => {
  return getShapeSelectorIcon(tool) !== 'shapes'
}

const getShapeSelectorPopupStyles = createStyleSheet(({ size }) => ({
  wrapper: {
    paddingHorizontal: size.A,
    paddingVertical: size.C
  },
  header: {
    paddingHorizontal: size.D,
    marginBottom: size.G
  },
  buttons: {
    flexDirection: 'row',
    gap: size.A
  }
}))
