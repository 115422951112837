import { IconName } from '@gopeerproject/ui-kit'
import { uuid } from '@gopeerproject/chuck'
import { assertContext } from '@/utils'
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useRef
} from 'react'

export type TToast = {
  id: string
  icon: IconName
  accent: 'default' | 'negative'
  title: string
  subtitle?: string
}

type ToastsContextState = TToast[]

type ToastsContextAction =
  | { type: 'add'; payload: TToast }
  | {
      type: 'dismiss'
      payload: { id: string } // id
    }

const reducer = (
  state: ToastsContextState,
  action: ToastsContextAction
): ToastsContextState => {
  switch (action.type) {
    case 'add':
      return [action.payload].concat(state)
    case 'dismiss':
      return state.filter((s) => s.id !== action.payload.id)
    default:
      return state
  }
}

const initialState: ToastsContextState = []

const ToastsContext = createContext<ToastsContextState>(initialState)
const ToastsDispatchContext = createContext<{
  addToast: (
    toast: Omit<TToast, 'id'> & { id?: string },
    options?: { selfDismiss: boolean }
  ) => void
  dismissToast: (id: string) => void
}>(null!)

export const ToastsProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const timersRef = useRef<Set<NodeJS.Timeout>>(new Set())

  const addToast = useCallback(
    (
      toast: Omit<TToast, 'id'> & { id?: string },
      options: { selfDismiss: boolean } = { selfDismiss: true }
    ) => {
      const id = toast.id ?? uuid()

      dispatch({
        type: 'add',
        payload: { ...toast, id }
      })

      if (!options.selfDismiss) return

      const handle = setTimeout(() => {
        dispatch({ type: 'dismiss', payload: { id } })
        timersRef.current.delete(handle)
      }, 10_000)

      timersRef.current.add(handle)
    },
    []
  )

  const dismissToast = useCallback((id: string) => {
    dispatch({ type: 'dismiss', payload: { id } })
  }, [])

  const api = useMemo(() => {
    return { addToast, dismissToast }
  }, [addToast, dismissToast])

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timersRef.current.forEach((timer) => {
        clearTimeout(timer)
      })
    }
  }, [])

  return (
    <ToastsContext.Provider value={state}>
      <ToastsDispatchContext.Provider value={api}>
        {children}
      </ToastsDispatchContext.Provider>
    </ToastsContext.Provider>
  )
}

export const useToasts = () => {
  return assertContext(useContext(ToastsContext), 'useToasts', 'ToastsContext')
}

export const useToastDispatch = () => {
  return assertContext(
    useContext(ToastsDispatchContext),
    'useToastDispatch',
    'ToastsDispatchContext'
  )
}
