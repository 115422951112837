import React from 'react'
import {
  createStyleSheet,
  useStyleSheet,
  View,
  Text
} from '@gopeerproject/ui-kit'

const getErrorContent = (error: Error) => {
  let headline = ''
  let message = ''

  switch (true) {
    case error?.name === 'NotAllowedError':
      headline = 'Unable to Access Media'

      if (error.message === 'Permission denied by system') {
        message =
          'The browser cannot access the microphone or camera. Please make sure you enable microphone/camera permissions for your browser from your system settings.'
      } else {
        message =
          'Seems like you denied permission to use audio and video. Please grant permission to the browser to access microphone and camera. Then refresh the page.'
      }
      break
    case error?.name === 'NotFoundError':
      headline = 'Cannot Find Microphone or Camera'
      message =
        'The browser cannot access the microphone or camera. Please make sure you enable microphone/camera permissions for your browser from your system settings.'
      break
    default:
      headline = 'Error Acquiring Media'
      message = `Please try rebooting your device! We were not able to access your media because of a hardrware issue.`
      break
  }

  return { headline, message }
}

export const ErrorMessage: React.FC<{ error: Error }> = ({ error }) => {
  const styles = useStyleSheet(getStyles)
  const { headline, message } = getErrorContent(error)

  return (
    <View style={styles.container}>
      <Text type='headline' size='md'>
        {headline}
      </Text>
      <Text type='body' size='md' weight='regular'>
        {message}
      </Text>
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: {
    backgroundColor: color.negative.solid,
    padding: size.E,
    borderRadius: size.G,
    marginBottom: size.G,
    gap: size.C,
    textAlign: 'center'
  }
}))
