import {
  createStyleSheet,
  Divider,
  Focusable,
  Icon,
  Text,
  useStyleSheet,
  useThemeContext,
  View,
  IconName
} from '@gopeerproject/ui-kit'
import React, { useMemo, useReducer, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useOutsideAlerter } from '@/utils'

type State = {
  isShown: boolean
  width: number
  height: number
  top: number
  left: number
}

type Action =
  | { type: 'SET_DIMENSIONS'; payload: Omit<State, 'isShown'> }
  | { type: 'SET_VISIBILITY'; payload: boolean }
  | { type: 'TOGGLE_VISIBILITY' }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_DIMENSIONS':
      return {
        ...state,
        ...action.payload
      }
    case 'SET_VISIBILITY':
      return {
        ...state,
        isShown: action.payload
      }
    case 'TOGGLE_VISIBILITY':
      return {
        ...state,
        isShown: !state.isShown
      }
  }
}

const initialState: State = {
  isShown: false,
  width: 0,
  height: 0,
  top: 0,
  left: 0
}

export type DropdownProps = {
  children: React.ReactNode
  data: {
    text: string
    onPress?: (e: any) => void
    rightIcon?: { name: IconName; color: string }
    disabled?: boolean
    leftIcon?: { name: IconName; color: string }
  }[]
}

export const Dropdown: React.FC<DropdownProps> = ({ data, children }) => {
  const styles = useStyleSheet(getStyles)

  const modalRoot = useMemo(() => document.getElementById('modal')!, [])
  const { size } = useThemeContext()

  const [{ isShown, height, width, top, left }, dispatch] = useReducer(
    reducer,
    initialState
  )

  const focusableRef = useRef<HTMLDivElement>()
  const dropdownRef = useRef<HTMLDivElement>()
  useOutsideAlerter(dropdownRef, () =>
    dispatch({ type: 'SET_VISIBILITY', payload: false })
  )

  return (
    <>
      <Focusable
        // @ts-expect-error
        ref={focusableRef}
        onPress={() => {
          if (!isShown && focusableRef.current) {
            const rect = focusableRef.current.getBoundingClientRect()
            dispatch({
              type: 'SET_DIMENSIONS',
              payload: {
                height: rect.height,
                width: rect.width,
                top: rect.top,
                left: rect.left
              }
            })
          }
          dispatch({ type: 'TOGGLE_VISIBILITY' })
        }}
        onMouseDown={(e: MouseEvent) => {
          if (isShown) {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
      >
        {children}
      </Focusable>
      {isShown &&
        createPortal(
          <View
            // @ts-expect-error
            ref={dropdownRef}
            style={[
              styles.container,
              {
                top: top + height + size.B,
                left: left,
                width: width
              }
            ]}
          >
            {data.map(({ text, onPress, rightIcon, leftIcon, disabled }, i) => (
              <React.Fragment key={i}>
                <Focusable
                  style={styles.item}
                  hoverStyle={styles.hoverItem}
                  pressStyle={styles.pressedItem}
                  disabledStyle={styles.disabledItem}
                  onPress={(e) => {
                    if (onPress) onPress(e)
                    dispatch({ type: 'SET_VISIBILITY', payload: false })
                  }}
                  disabled={disabled}
                >
                  {leftIcon && <Icon size={16} {...leftIcon} />}
                  <Text
                    type='body'
                    size='md'
                    weight='semibold'
                    style={styles.itemText}
                  >
                    {text}
                  </Text>
                  {rightIcon && <Icon size={16} {...rightIcon} />}
                </Focusable>
                {i !== data.length - 1 && (
                  <Divider style={styles.divider} type='horizontal' />
                )}
              </React.Fragment>
            ))}
          </View>,
          modalRoot
        )}
    </>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: {
    position: 'absolute',
    gap: size.C,
    padding: size.C,
    backgroundColor: color.bg.v3,
    borderColor: color.invert2.p4,
    borderStyle: 'solid',
    borderWidth: 1,
    borderRadius: size.G,
    alignItems: 'stretch',
    opacity: 1,
    visibility: 'visible',
    zIndex: 999
  },
  hiddenContainer: { opacity: 0, visibility: 'hidden' },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    borderRadius: size.C,
    padding: size.E,
    textAlign: 'left'
  },
  hoverItem: { backgroundColor: color.invert2.p10 },
  pressedItem: { backgroundColor: color.invert2.p10, opacity: 0.7 },
  disabledItem: { opacity: 0.42 },
  itemText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    textAlign: 'left',
    flexGrow: 1
  },
  divider: {
    height: 1,
    backgroundColor: color.invert2.p4,
    marginHorizontal: size.E
  }
}))
