import React, {
  useEffect,
  useContext,
  useState,
  useRef,
  MutableRefObject
} from 'react'
import {
  Button,
  createStyleSheet,
  IconButton,
  TabNavigation,
  Text,
  useStyleSheet,
  View,
  Badge,
  useThemeContext
} from '@gopeerproject/ui-kit'
import { addStyles, EditableMathField, MathField } from 'react-mathquill'

import { createEmptyBoard, createFunctionGraph } from './helpers'
import { Formula } from './Formula'
import { Graph } from './Graph'
import { SK } from '../constants'
import { EditorContext } from '../contexts'
import { PortalModal } from '@gopeerproject/web-kit'
import { Board, Axis } from 'jsxgraph'

addStyles()

export const FormulaGraphTool = ({
  onClose,
  onSave,
  canvas
}: {
  onClose: () => void
  onSave: () => void
  canvas: MutableRefObject<fabric.Canvas | undefined>
}) => {
  const styles = useStyleSheet(getStyles)
  const { color, size, colorScheme } = useThemeContext()

  const [activeObject] = canvas.current?.getActiveObjects() ?? []
  const activeObjectType = activeObject instanceof Graph ? 'graph' : 'formula'

  const [latex, setLatex] = useState(activeObject?.data || 'x')
  const [activeTab, setActiveTab] = useState(
    activeObject instanceof Formula ? 'formula' : 'graph'
  )
  const inputRef = useRef<MathField>()
  const functionGraph = useRef<any>()
  const boardRef = useRef<Board>()
  const axisRef = useRef<{ xAxis: Axis; yAxis: Axis }>()

  const isEditMode = !!activeObject
  const isUpdatingFormula = isEditMode && activeObjectType === activeTab
  const isUpdatingType = isEditMode && activeObjectType !== activeTab

  const insertCommand = (command: string) => () => {
    inputRef.current!.cmd(`\\${command}`)
    inputRef.current!.focus()
  }

  const deleteGraph = () => {
    if (functionGraph.current) {
      functionGraph.current.remove()
      functionGraph.current.removeAllTicks()
      functionGraph.current = null
    }
  }

  useEffect(() => {
    if (activeTab === 'graph') {
      const { board, axis } = createEmptyBoard('board', true)

      boardRef.current = board
      axisRef.current = axis

      return deleteGraph
    }
  }, [activeTab])

  useEffect(() => {
    if (activeTab !== 'graph') return

    if (functionGraph.current) {
      deleteGraph()
    }

    boardRef.current!.zoom100()
    functionGraph.current = createFunctionGraph(boardRef.current!, latex)
  }, [latex, activeTab])

  const createObject = () => {
    const object =
      activeTab === 'graph'
        ? new Graph({ data: latex })
        : new Formula({ data: latex })

    canvas.current?.add(object)
    onSave()

    return object
  }

  const onClick = () => {
    let object: any
    switch (true) {
      case isUpdatingFormula: {
        object = canvas.current
          ?.getObjects()
          // @ts-ignore
          .find((object) => object.id === activeObject.id)

        object.set('data', latex)
        object.fire('modified', { data: latex })
        onSave()
        // Heap.trigger(`Formula Graph Tool - ${capitalize(activeTab)} - Update`)
        break
      }

      case isUpdatingType: {
        const object = canvas.current
          ?.getObjects()
          // @ts-ignore
          .find((object) => object.id === activeObject.id)

        if (object) canvas.current!.remove(object)
        createObject()
        break
      }

      default:
        object = createObject()
    }

    deleteGraph()
    onClose()
    setTimeout(() => {
      canvas.current?.setActiveObject(object)
      canvas.current?.renderAll()
    })
  }

  return (
    <PortalModal onClose={onClose}>
      <View
        style={[styles.modal, activeTab === 'graph' && styles.modalGraph]}
        nativeID='formula-modal'
      >
        <View style={styles.header}>
          <TabNavigation
            onChange={(value) => setActiveTab(value)}
            options={[
              { value: 'graph', name: 'Graph' },
              { value: 'formula', name: 'Formula' }
            ]}
            value={activeTab}
            style={styles.tabs}
          />
          <IconButton
            value='dismiss'
            type='tertiary'
            accent='neutral'
            onPress={onClose}
            size='sm'
          />
        </View>

        <View style={styles.main}>
          <View style={styles.formulaInput}>
            <View style={styles.formulaInputTop}>
              <View>
                <Text size='lg' type='body' weight='regular'>
                  f(x) =
                </Text>
              </View>
              <View style={styles.formulaInputEditable}>
                <EditableMathField
                  className={`mq-colorScheme-${colorScheme}`}
                  style={{
                    color: color.invert2.p100,
                    paddingLeft: size.C
                  }}
                  latex={latex}
                  mathquillDidMount={(mathField) =>
                    (inputRef.current = mathField)
                  }
                  onChange={(field) => setLatex(field.latex())}
                />
              </View>
            </View>

            <View style={styles.formulaInputFooter}>
              <IconButton
                value='sqrt'
                accent='neutral'
                size='xs'
                type='tertiary'
                onPress={insertCommand('sqrt')}
              />
              <IconButton
                value='infinity'
                accent='neutral'
                size='xs'
                type='tertiary'
                onPress={insertCommand('infinity')}
              />
              <IconButton
                value='plus-minus'
                accent='neutral'
                size='xs'
                type='tertiary'
                onPress={insertCommand('pm')}
              />
              <IconButton
                value='pi'
                accent='neutral'
                size='xs'
                type='tertiary'
                onPress={insertCommand('pi')}
              />
              <IconButton
                value='autosum'
                accent='neutral'
                size='xs'
                type='tertiary'
                onPress={insertCommand('sum')}
              />
              <IconButton
                value='triangle'
                accent='neutral'
                size='xs'
                type='tertiary'
                onPress={insertCommand('triangle')}
              />
            </View>
          </View>
          {activeTab === 'graph' ? (
            <>
              <Text
                weight='regular'
                size='md'
                type='body'
                style={styles.secondaryText}
              >
                Use x as a variable to be able to plot graphs. Example: x^2 +
                sin(x)
              </Text>

              <div
                id='board'
                style={{
                  height: 242,
                  backgroundColor: color.bg.v1,
                  borderRadius: size.G
                }}
              />

              <View style={styles.shortcutsTip}>
                <Text
                  weight='regular'
                  size='md'
                  type='body'
                  style={styles.secondaryText}
                >
                  Hold
                </Text>
                <Badge
                  style={styles.badge}
                  textStyle={styles.badgeText}
                  text='shift + drag'
                  withBackground
                />
                <Text
                  weight='regular'
                  size='md'
                  type='body'
                  style={styles.secondaryText}
                >
                  to reposition. Hold
                </Text>
                <Badge
                  style={styles.badge}
                  text='shift + scroll'
                  textStyle={styles.badgeText}
                  withBackground
                />
                <Text
                  weight='regular'
                  size='md'
                  type='body'
                  style={styles.secondaryText}
                >
                  for zomm in and out.
                </Text>
              </View>
            </>
          ) : (
            <>
              <Text
                weight='regular'
                size='md'
                type='body'
                style={styles.secondaryText}
              >
                Full LaTeX commands are supported (e.g. pi, sqrt, integral).
              </Text>

              <View style={styles.shortcutsTip}>
                <Text
                  weight='regular'
                  size='md'
                  type='body'
                  style={styles.secondaryText}
                >
                  Use
                </Text>
                <Badge
                  style={styles.badge}
                  textStyle={styles.badgeText}
                  text='/'
                  withBackground
                />
                <Text
                  weight='regular'
                  size='md'
                  type='body'
                  style={styles.secondaryText}
                >
                  for fractions and
                </Text>
                <Badge
                  style={styles.badge}
                  text='^'
                  textStyle={styles.badgeText}
                  withBackground
                />
                <Text
                  weight='regular'
                  size='md'
                  type='body'
                  style={styles.secondaryText}
                >
                  powers
                </Text>
              </View>
            </>
          )}
        </View>

        <View style={styles.footer}>
          <Button
            type='tertiary'
            accent='neutral'
            onPress={onClose}
            text='Cancel'
            size='md'
          />
          <Button
            type='primary'
            accent='default'
            onPress={onClick}
            text={`${isUpdatingFormula ? 'Update' : 'Insert'} ${activeTab}`}
            size='md'
          />
        </View>
      </View>
    </PortalModal>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  modal: {
    width: 596,
    height: 'auto',
    paddingHorizontal: size.K,
    paddingBottom: size.K,
    gap: size.G
  },
  modalGraph: {
    // height: 430
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tabs: {
    flexBasis: '50%'
  },
  footer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: size.C
  },
  main: {
    paddingTop: size.C,
    gap: size.G
  },
  shortcutsTip: {
    flexDirection: 'row',
    gap: size.C,
    alignItems: 'center'
  },
  badge: {
    backgroundColor: color.invert2.p4,
    paddingHorizontal: size.C,
    paddingVertical: 3
  },
  badgeText: {
    textTransform: 'uppercase',
    color: color.invert2.p100,
    fontWeight: 600
  },
  secondaryText: {
    color: color.invert2.p56
  },
  formulaInput: {
    padding: size.C,
    gap: size.C,
    backgroundColor: color.bg.v1,
    borderRadius: size.G
  },
  formulaInputTop: {
    padding: size.C,
    paddingBottom: size.E,
    flexDirection: 'row',
    alignItems: 'center',
    height: size.P
  },
  formulaInputEditable: {
    flex: 1
  },
  formulaInputFooter: {
    flexDirection: 'row',
    alignItems: 'center'
  }
}))

export const FormulaGraphToolModal = (props: { onSave: () => void }) => {
  const {
    state: { tool },
    setEditorState,
    canvas
  } = useContext(EditorContext)

  const onClose = React.useCallback(() => {
    setEditorState({ tool: SK.TOOLS.SELECT })
  }, [setEditorState])

  if (tool !== SK.TOOLS.MATH) return null

  return (
    <FormulaGraphToolModalErrorBoundary>
      <FormulaGraphTool {...props} onClose={onClose} canvas={canvas} />
    </FormulaGraphToolModalErrorBoundary>
  )
}

class FormulaGraphToolModalErrorBoundary extends React.Component<
  any,
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error: unknown) {
    // Sentry.track(error)
    console.error(error)
  }

  render() {
    if (this.state.hasError) {
      return null
    }

    return this.props.children
  }
}
