import {
  EndCallButton,
  ToggleAudioButton,
  ToggleScreenShareButton,
  ToggleVideoButton,
  SettingsButton
} from '../Media/Controls'
import { createStyleSheet, useStyleSheet, View } from '@gopeerproject/ui-kit'
import { Feature, useClassroomPermissions } from '../../contexts'

export const Footer: React.FC<{ withControls?: boolean }> = ({
  withControls
}) => {
  const styles = useStyleSheet(getStyles)
  const { checkPermission } = useClassroomPermissions()
  return (
    <View style={styles.container}>
      {withControls && (
        <>
          <ToggleAudioButton />
          <ToggleVideoButton />
          {checkPermission(Feature.SCREEN_SHARE_TOGGLE) ? (
            <ToggleScreenShareButton />
          ) : null}
          <SettingsButton />
          <EndCallButton />
        </>
      )}
    </View>
  )
}

const getStyles = createStyleSheet(({ size }) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: size.K,
    gap: size.G
  }
}))
