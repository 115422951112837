import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'

if (import.meta.env.VITE_NR_ENABLED === 'true') {
  const options = {
    init: JSON.parse(import.meta.env.VITE_NR_INIT),
    info: JSON.parse(import.meta.env.VITE_NR_INFO),
    loader_config: JSON.parse(import.meta.env.VITE_NR_LOADER_CONFIG)
  }

  new BrowserAgent(options)
}
