import { useCallback, useEffect, useRef } from 'react'
import { useLocalMediaToggle, useDevices } from '@gopeerproject/web-kit'
import { IconButton } from '@gopeerproject/ui-kit'

import { MediaRestrictionStatus } from '@/data'
import { useMemberControlsQuery } from '../../../hooks'

export const ToggleVideoButton = () => {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalMediaToggle('video')
  const lastClickTimeRef = useRef(0)
  const { hasVideoInputDevices } = useDevices()

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()
      toggleVideoEnabled()
    }
  }, [toggleVideoEnabled])

  // useEffect(() => {
  //   ClassroomManager.updateParticipant({ video: isVideoEnabled })
  // }, [isVideoEnabled])

  const { data } = useMemberControlsQuery()
  const { video: videoRestrictionStatus } = data!

  useEffect(() => {
    if (
      videoRestrictionStatus === MediaRestrictionStatus.RESTRICTED &&
      isVideoEnabled
    ) {
      toggleVideoEnabled()
    }
  }, [isVideoEnabled, videoRestrictionStatus, toggleVideoEnabled])

  return (
    <IconButton
      type='tertiary'
      size='lg'
      accent='neutral'
      onPress={toggleVideo}
      value={hasVideoInputDevices && isVideoEnabled ? 'video' : 'video-off'}
      disabled={videoRestrictionStatus === MediaRestrictionStatus.RESTRICTED}
      active
    />
  )
}
