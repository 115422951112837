import {
  Button,
  createStyleSheet,
  IconButton,
  Text,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'
import { useOutsideAlerter } from '@gopeerproject/web-kit'
import React, { useRef, useState } from 'react'
import { useClassroomQuery, useClassroomUpdate } from '../../hooks'

export const Overtime: React.FC<{ onClose: () => void; timeLeft: string }> = ({
  onClose,
  timeLeft
}) => {
  const { mutateAsync: updateClassroom, isLoading } = useClassroomUpdate()
  const { data: classroom } = useClassroomQuery()
  const [overtime, setOvertime] = useState(5)
  const styles = useStyleSheet(getStyles)
  const ref = useRef<HTMLDivElement | null>(null)
  useOutsideAlerter(ref, onClose)

  return (
    <View
      style={styles.container}
      // @ts-expect-error
      ref={ref}
    >
      <View>
        <Text type='body' size='md' weight='semibold'>
          Add time to session
        </Text>
        <Text type='body' size='md' style={styles.timeLeft} weight='regular'>
          You have left {timeLeft}
        </Text>
      </View>
      <View style={styles.numberInput}>
        <IconButton
          type='tertiary'
          size='md'
          value='subtract'
          accent='neutral'
          active
          disabled={isLoading || overtime === 5}
          onPress={() =>
            !isLoading && overtime > 5 && setOvertime(overtime - 5)
          }
        />
        <Text type='headline' size='md' style={styles.overtimeText}>
          {overtime}m
        </Text>
        <IconButton
          type='tertiary'
          size='md'
          value='add'
          accent='neutral'
          active
          disabled={isLoading || overtime === 60}
          onPress={() =>
            !isLoading && overtime < 60 && setOvertime(overtime + 5)
          }
        />
      </View>
      <Button
        type='primary'
        accent='default'
        size='md'
        text='Extend'
        onPress={() => {
          updateClassroom({ overtime: overtime + classroom!.overtime }).then(
            () => {
              setOvertime(5)
              onClose()
            }
          )
        }}
        isLoading={isLoading}
        disabled={isLoading}
        centered
      />
    </View>
  )
}

const getStyles = createStyleSheet(({ color, size }) => ({
  container: {
    padding: size.G,
    position: 'absolute',
    right: '100%',
    backgroundColor: color.bg.v2,
    borderColor: color.invert2.p4,
    borderWidth: 1,
    borderRadius: size.G,
    boxShadow: '0px 8px 42px rgba(28, 29, 40, 0.1)',
    alignItems: 'center',
    gap: size.G,
    width: 256
  },
  timeLeft: { color: color.invert2.p56 },
  numberInput: {
    padding: size.B,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: color.invert2.p4,
    borderRadius: size.M + size.E
  },
  overtimeText: { width: size.L, marginHorizontal: size.F, textAlign: 'center' }
}))
