import { Button, createStyleSheet, useStyleSheet } from '@gopeerproject/ui-kit'
import React from 'react'

import { GlobalMessage } from '@/components'

export const Intro: React.FC<{ next: () => void }> = ({ next }) => {
  const styles = useStyleSheet(getStyles)
  return (
    <>
      <GlobalMessage
        emoji='👋'
        title='Welcome to your Classroom'
        description="We'll need access to your camera and microphone for real-time audio/video. Let's get started."
      />
      <Button
        type='primary'
        centered
        size='lg'
        accent='default'
        onPress={next}
        text="Let's get started"
        style={styles.button}
      />
    </>
  )
}

const getStyles = createStyleSheet(({ size }) => ({
  button: { marginTop: size.K }
}))
