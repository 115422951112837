import {
  createContext,
  createRef,
  MutableRefObject,
  useContext,
  useRef
} from 'react'
import { assertContext as assert } from '@/utils'
import { ClassroomManager } from '../utils/ClassroomManager'

const classroomManagerRefContext = createContext<
  MutableRefObject<ClassroomManager | null>
>(createRef())

export const ClassroomManagerRefProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const classroomManagerRef = useRef<ClassroomManager | null>(null)
  return (
    <classroomManagerRefContext.Provider value={classroomManagerRef}>
      {children}
    </classroomManagerRefContext.Provider>
  )
}

export const useClassroomManagerRef = () => {
  return assert(
    useContext(classroomManagerRefContext)!,
    'useClassroomManagerRef',
    'ClassroomManagerRefProvider'
  )
}
