import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  useFileUpload,
  useAPI,
  useProfileContext,
  StaticFile
} from '@gopeerproject/chuck'

export const useBackgrounds = () => {
  const { get } = useAPI()
  return useQuery(
    'backgrounds',
    () =>
      get({ url: `/files?type=video_background` }).then(
        (response) => response.data as StaticFile[]
      ),
    { initialData: [], enabled: false }
  )
}

export const useUploadBackground = () => {
  const queryClient = useQueryClient()
  const uploadFile = useFileUpload()
  const { profile } = useProfileContext()
  return useMutation(
    (file: File) =>
      uploadFile(
        file,
        {
          type: 'video_background',
          name: file.name,
          participants: [profile!.id]
        },
        {}
      ),
    {
      onSuccess: (background) => {
        queryClient.setQueryData(
          'backgrounds',
          (backgrounds: StaticFile[] | undefined) => {
            if (!backgrounds) return [background]
            return [background, ...backgrounds]
          }
        )
      }
    }
  )
}
