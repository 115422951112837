import React, { useEffect, useRef } from 'react'
import {
  useLocalMediaToggle,
  useMediaContext,
  VideoTrack
} from '@gopeerproject/web-kit'
import {
  createStyleSheet,
  IconButton,
  Text,
  useStyleSheet,
  View
} from '@gopeerproject/ui-kit'

import { WaitingRoomStatus } from '@/data'
import { JoinButton } from './JoinButton'

import { AudioInputSetting, AudioOutputList, VideoInputList } from '../Media'
import { ErrorMessage, Backgrounds } from '../Settings'
import { useMemberControlsQuery } from '../../hooks'

export const Devices: React.FC<{ next: () => void }> = ({ next }) => {
  const styles = useStyleSheet(getStyles)
  const { data: memberControls } = useMemberControlsQuery()

  const {
    localTracks,
    getAudioAndVideoTracks,
    error,
    setError,
    isConnecting,
    isAcquiringLocalTracks
  } = useMediaContext()
  const [isAudioEnabled, toggleAudioEnabled] = useLocalMediaToggle('audio')
  const [isVideoEnabled, toggleVideoEnabled] = useLocalMediaToggle('video')
  const localVideoTrack = localTracks.find((track) => track.kind === 'video')
  const errorRef = useRef(null)

  useEffect(() => {
    if (!errorRef.current) {
      getAudioAndVideoTracks().catch((error) => {
        errorRef.current = error
        setError(error)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error, setError])

  return (
    <View style={styles.container}>
      <View style={styles.leftContainer}>
        <View style={styles.videoPreview}>
          {localVideoTrack && <VideoTrack isLocal track={localVideoTrack} />}
          <View style={styles.videoPreviewButtons}>
            <IconButton
              type='tertiary'
              size='lg'
              accent='neutral'
              active
              value={isAudioEnabled ? 'mic' : 'mic-off'}
              onPress={toggleAudioEnabled}
            />
            <IconButton
              type='tertiary'
              size='lg'
              accent='neutral'
              active
              value={isVideoEnabled ? 'video' : 'video-off'}
              onPress={toggleVideoEnabled}
            />
          </View>
        </View>
        <Text type='headline' size='md'>
          Backgrounds
        </Text>
        <Backgrounds isOnboarding />
      </View>
      <View style={styles.rightContainer}>
        {error && <ErrorMessage error={error} />}
        <Text type='heading' size='h4'>
          Check audio/video Settings
        </Text>
        <View style={styles.controls}>
          <AudioOutputList />
          <AudioInputSetting />
          <VideoInputList />
          {memberControls!.status !== WaitingRoomStatus.ADMITTED ? (
            <Text
              type='body'
              size='md'
              weight='regular'
              style={styles.waitingRoomMessage}
            >
              Waiting for the tutor to admit you, in the meantime you can check
              your audio and video settings.
            </Text>
          ) : null}
        </View>

        <JoinButton
          next={next}
          styles={styles}
          isConnecting={isConnecting}
          isAcquiringLocalTracks={isAcquiringLocalTracks}
        />
      </View>
    </View>
  )
}

const getStyles = createStyleSheet(({ size, color }) => ({
  container: {
    flexDirection: 'row',
    width: 980,
    paddingBottom: size.M,
    alignItems: 'center'
  },
  leftContainer: { flexBasis: '45%', gap: size.G },
  rightContainer: {
    flexBasis: '55%',
    gap: size.K,
    alignItems: 'center',
    padding: size.K
  },
  videoPreview: {
    borderRadius: size.G,
    overflow: 'hidden',
    backgroundColor: '#000',
    height: 330
  },
  videoPreviewButtons: {
    position: 'absolute',
    left: '50%',
    bottom: size.G,
    transform: 'translateX(-50%)',
    padding: size.B,
    borderRadius: 58,
    backgroundColor: 'rgba(0,0,0,0.68)',
    flexDirection: 'row',
    gap: size.B
  },
  controls: { gap: size.G, width: 360 },
  waitingRoomMessage: {
    color: color.invert2.p56,
    marginTop: size.C,
    textAlign: 'center'
  }
}))
