/** @ref https://codesandbox.io/s/distracted-swirles-jo1pvu?file=/src/App.tsx */
import {
  useFloating,
  autoUpdate,
  offset,
  shift,
  useClick,
  useDismiss,
  useRole,
  useInteractions,
  Placement
} from '@floating-ui/react'
import { assertContext } from '@/utils'
import { useContext, createContext, useState, useMemo } from 'react'

export const usePopover = ({
  initialOpen = false,
  placement = 'right-start',
  modal,
  open: controlledOpen,
  onOpenChange: setControlledOpen
}: {
  initialOpen?: boolean
  placement: Placement
  modal: boolean
  open: boolean
  onOpenChange: (isOpen: boolean) => void
}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const [labelId, setLabelId] = useState()
  const [descriptionId, setDescriptionId] = useState()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({
        mainAxis: 8,
        crossAxis: -8
      }),
      shift({ padding: 5 })
    ]
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null
  })
  const dismiss = useDismiss(context)
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      modal,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId
    }),
    [open, setOpen, interactions, data, modal, labelId, descriptionId]
  )
}

export const PopoverContext = createContext<
  ReturnType<typeof usePopover> | undefined
>(undefined)

export const usePopoverContext = () => {
  return assertContext(
    useContext(PopoverContext)!,
    'usePopoverContext',
    'PopoverContextProvider'
  )
}
